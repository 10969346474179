

export class OwnerTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_OwnerTypeDto {
    GUID: string;
    Name: string;

}


export class List_OwnerTypeDto {
    GUID: string;
    Name: string;

}
export class PList_OwnerTypeDto {
Data: List_OwnerTypeDto[];
TotalCount: number;
}
