

export class TaskActionDto {
    GUID: string;
    Name: string;
    FormName: string;
    ServicePath: string;

}


export class Combo_TaskActionDto {
    GUID: string;
    Name: string;
    FormName: string;
    ServicePath: string;

}


export class List_TaskActionDto {
    GUID: string;
    Name: string;
    FormName: string;
    ServicePath: string;

}
export class PList_TaskActionDto {
Data: List_TaskActionDto[];
TotalCount: number;
}
