

export class RoleComponentActionDto {
  GUID: string;
  RoleGUID: string;
  ComponentActionGUIDs: string[];

}


export class List_RoleComponentActionDto {
  GUID: string;
  SoftwareGUID: string;
  SoftwareName: string;
  RoleGUID: string;
  RoleName: string;
  ComponentGUID: string;
  ComponentName: string;
  ComponentActionGUID: string;
  ComponentActionName: string;
}

export class PList_RoleComponentActionDto {
  Data: List_RoleComponentActionDto[];
  TotalCount: number;
}

export class Filter_RoleComponentActionDto {
  RoleGUID: string;
  SoftwareGUID: string;
  ComponentGUIDs: string[];
}
