

export class AccountingTitleGroupDto {
    GUID: string;
    Name: string;

}


export class Combo_AccountingTitleGroupDto {
    GUID: string;
    Name: string;

}


export class List_AccountingTitleGroupDto {
    GUID: string;
    Name: string;

}
export class PList_AccountingTitleGroupDto {
Data: List_AccountingTitleGroupDto[];
TotalCount: number;
}
