import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from 'src/app/shared/error.service';
import { Injectable } from '@angular/core';


import { UserRoleDto, List_UserRoleDto, PList_UserRoleDto, Filter_UserRoleDto} from './user-role.model'



const serviceName= "UserRoleService"


@Injectable({
 providedIn: 'root',
})
export class UserRoleService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<UserRoleDto> {
   let returnObject = new UserRoleDto()
   await this.RepositoryService.getItemAsync('UserRole/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<UserRoleDto> {
   let returnObject = new UserRoleDto()
   await this.RepositoryService.getItemAsync('UserRole/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_UserRole) => {
           returnObject = _UserRole;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_UserRoleDto>
{
   let returnList = new PList_UserRoleDto();
   await this.RepositoryService.getAllAsync('UserRole/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_UserRoleDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllByUserGUID(_Filter_UserRoleDto: Filter_UserRoleDto ,pagingString = ''): Promise<PList_UserRoleDto> {
       let returnList = new PList_UserRoleDto();
     await  this.RepositoryService.getAllExteraAsync('UserRole/Get_AllByUserGUID/' + pagingString,_Filter_UserRoleDto)
         .then((res) => {
             returnList.Data = res['value'] as List_UserRoleDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_UserRoleDto: UserRoleDto): Promise<UserRoleDto> {
           let returnvalue: UserRoleDto = null;
           await this.RepositoryService.createAsync('UserRole/Create_Custom', _UserRoleDto)
             .then((res) => {
                 returnvalue = res as UserRoleDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_UserRoleDto: UserRoleDto): Promise<UserRoleDto> {
               let returnvalue: UserRoleDto = null;
               await this.RepositoryService.updateAsync('UserRole/Update_Custom', _UserRoleDto)
                 .then((res) => {
                     returnvalue = res as UserRoleDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('UserRole/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }
}
