import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { ExpiredZemanatnamehTypeDto, Combo_ExpiredZemanatnamehTypeDto, List_ExpiredZemanatnamehTypeDto, PList_ExpiredZemanatnamehTypeDto} from './expired-zemanatnameh-type.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "ExpiredZemanatnamehTypeService"


@Injectable({
 providedIn: 'root',
})
export class ExpiredZemanatnamehTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<ExpiredZemanatnamehTypeDto> {
   let returnObject = new ExpiredZemanatnamehTypeDto()
   await this.RepositoryService.getItemAsync('ExpiredZemanatnamehType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<ExpiredZemanatnamehTypeDto> {
   let returnObject = new ExpiredZemanatnamehTypeDto()
   await this.RepositoryService.getItemAsync('ExpiredZemanatnamehType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_ExpiredZemanatnamehType) => {
           returnObject = _ExpiredZemanatnamehType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_ExpiredZemanatnamehTypeDto>
{
   let returnList = new PList_ExpiredZemanatnamehTypeDto();
   await this.RepositoryService.getAllAsync('ExpiredZemanatnamehType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_ExpiredZemanatnamehTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_ExpiredZemanatnamehTypeDto> {
       let returnList = new PList_ExpiredZemanatnamehTypeDto();
     await  this.RepositoryService.getAllAsync('ExpiredZemanatnamehType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_ExpiredZemanatnamehTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_ExpiredZemanatnamehTypeDto: ExpiredZemanatnamehTypeDto): Promise<ExpiredZemanatnamehTypeDto> {
           let returnvalue: ExpiredZemanatnamehTypeDto = null;
           await this.RepositoryService.createAsync('ExpiredZemanatnamehType/Create_Custom', _ExpiredZemanatnamehTypeDto)
             .then((res) => {
                 returnvalue = res as ExpiredZemanatnamehTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_ExpiredZemanatnamehTypeDto: ExpiredZemanatnamehTypeDto): Promise<ExpiredZemanatnamehTypeDto> {
               let returnvalue: ExpiredZemanatnamehTypeDto = null;
               await this.RepositoryService.updateAsync('ExpiredZemanatnamehType/Update_Custom', _ExpiredZemanatnamehTypeDto)
                 .then((res) => {
                     returnvalue = res as ExpiredZemanatnamehTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('ExpiredZemanatnamehType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_ExpiredZemanatnamehTypeDto[]> {
 let returnvalue: Combo_ExpiredZemanatnamehTypeDto[] = [];
         await this.RepositoryService.getAllAsync('ExpiredZemanatnamehType/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_ExpiredZemanatnamehTypeDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
