

export class ManifestTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_ManifestTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_ManifestTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_ManifestTypeDto {
Data: List_ManifestTypeDto[];
TotalCount: number;
}
