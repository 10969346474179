export const environment = {
  production: true,
  EmptyGUID: '00000000-0000-0000-0000-000000000000',
  SoftwareGUID: '0805f7fd-098b-4a52-9193-b64ff066c109',
  defaultLang: 'en',
  odataUrl: 'http://bankloanapi2.mjokar.ir/odata/Setaregan',
  apiUrl: 'http://bankloanapi2.mjokar.ir/api',
  apiVersion: 'v1',
  appVersion: 'v1.001.2',
  downloadURL: 'http://bankloanapi2.mjokar.ir/',
  homeRoot: '/Loan/loan/RequestForms/9BCDB719-5D6D-409E-9667-B1045C435C94',
};
