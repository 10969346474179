import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from 'src/app/shared/error.service';
import { Injectable } from '@angular/core';


import { FlowStepDto, Combo_FlowStepDto, List_FlowStepDto, PList_FlowStepDto} from './flow-step.model'



const serviceName= "FlowStepService"


@Injectable({
 providedIn: 'root',
})
export class FlowStepService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<FlowStepDto> {
   let returnObject = new FlowStepDto()
   await this.RepositoryService.getItemAsync('FlowStep/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<FlowStepDto> {
   let returnObject = new FlowStepDto()
   await this.RepositoryService.getItemAsync('FlowStep/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_FlowStep) => {
           returnObject = _FlowStep;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_FlowStepDto>
{
   let returnList = new PList_FlowStepDto();
   await this.RepositoryService.getAllAsync('FlowStep/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_FlowStepDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_FlowStepDto> {
       let returnList = new PList_FlowStepDto();
     await  this.RepositoryService.getAllAsync('FlowStep/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_FlowStepDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_FlowStepDto: FlowStepDto): Promise<FlowStepDto> {
           let returnvalue: FlowStepDto = null;
           await this.RepositoryService.createAsync('FlowStep/Create_Custom', _FlowStepDto)
             .then((res) => {
                 returnvalue = res as FlowStepDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_FlowStepDto: FlowStepDto): Promise<FlowStepDto> {
               let returnvalue: FlowStepDto = null;
               await this.RepositoryService.updateAsync('FlowStep/Update_Custom', _FlowStepDto)
                 .then((res) => {
                     returnvalue = res as FlowStepDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('FlowStep/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }
}
