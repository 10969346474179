import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { ZaribTypeDto, Combo_ZaribTypeDto, List_ZaribTypeDto, PList_ZaribTypeDto} from './zarib-type.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "ZaribTypeService"


@Injectable({
 providedIn: 'root',
})
export class ZaribTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<ZaribTypeDto> {
   let returnObject = new ZaribTypeDto()
   await this.RepositoryService.getItemAsync('ZaribType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<ZaribTypeDto> {
   let returnObject = new ZaribTypeDto()
   await this.RepositoryService.getItemAsync('ZaribType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_ZaribType) => {
           returnObject = _ZaribType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_ZaribTypeDto>
{
   let returnList = new PList_ZaribTypeDto();
   await this.RepositoryService.getAllAsync('ZaribType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_ZaribTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_ZaribTypeDto> {
       let returnList = new PList_ZaribTypeDto();
     await  this.RepositoryService.getAllAsync('ZaribType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_ZaribTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_ZaribTypeDto: ZaribTypeDto): Promise<ZaribTypeDto> {
           let returnvalue: ZaribTypeDto = null;
           await this.RepositoryService.createAsync('ZaribType/Create_Custom', _ZaribTypeDto)
             .then((res) => {
                 returnvalue = res as ZaribTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_ZaribTypeDto: ZaribTypeDto): Promise<ZaribTypeDto> {
               let returnvalue: ZaribTypeDto = null;
               await this.RepositoryService.updateAsync('ZaribType/Update_Custom', _ZaribTypeDto)
                 .then((res) => {
                     returnvalue = res as ZaribTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('ZaribType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }
}
