// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  EmptyGUID: '00000000-0000-0000-0000-000000000000',
  SoftwareGUID: '0805f7fd-098b-4a52-9193-b64ff066c109',
  defaultLang: 'en',
  odataUrl: 'https://setareganapi.armandar.com/odata/Setaregan',
  apiUrl: 'https://setareganapi.armandar.com/api',
  apiVersion: 'v1',
  appVersion: 'v1.001.2',
  downloadURL: 'https://setareganapi.armandar.com',
  homeRoot: '/Loan/loan/RequestForms/9BCDB719-5D6D-409E-9667-B1045C435C94',
  ReportURL: 'http://94.182.178.182/ReportServerSSRS/Pages/ReportViewer.aspx?%2f%DA%AF%D8%B2%D8%A7%D8%B1%D8%B4%D8%A7%D8%AA+%D8%AA%D9%88%D9%84%DB%8C%D8%AF1%2f',
  ReportFixParameter: '&rs:Command=Render'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
