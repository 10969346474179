import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from 'src/app/shared/error.service';
import { Injectable } from '@angular/core';


import { UserDto, List_UserDto, PList_UserDto, List_UserPostDto } from './user.model'



const serviceName = "SetareganUser"


@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<UserDto> {
    let returnObject = new UserDto()
    await this.RepositoryService.getItemAsync(serviceName + '/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<UserDto> {
    let returnObject = new UserDto()
    await this.RepositoryService.getItemAsync(serviceName + '/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_User) => {
          returnObject = _User;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_UserDto> {
    let returnList = new PList_UserDto();
    await this.RepositoryService.getAllAsync(serviceName + '/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_UserDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_UserDto> {
    let returnList = new PList_UserDto();
    await this.RepositoryService.getAllAsync(serviceName + '/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_UserDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }




  async Create_Custom(_UserDto: UserDto): Promise<UserDto> {
    let returnvalue: UserDto = null;
    await this.RepositoryService.createAsync(serviceName + '/Create_Custom', _UserDto)
      .then((res) => {
        returnvalue = res as UserDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_UserDto: UserDto): Promise<UserDto> {
    let returnvalue: UserDto = null;
    await this.RepositoryService.updateAsync(serviceName + '/Update_Custom', _UserDto)
      .then((res) => {
        returnvalue = res as UserDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync(serviceName + '/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  
  async Get_AllAccessPost(pagingString = ''): Promise<List_UserPostDto[]> {
    let returnList: List_UserPostDto[] = [];
    await this.RepositoryService.getAllAsync(serviceName + '/Get_AllAccessPost/' + pagingString)
      .then((res) => {
        returnList = res['value'] as List_UserPostDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }

}
