

export class FlowDto {
    GUID: string;
    Name: string;

}


export class Combo_FlowDto {
    GUID: string;
    Name: string;

}


export class List_FlowDto {
    GUID: string;
    Name: string;

}
export class PList_FlowDto {
Data: List_FlowDto[];
TotalCount: number;
}
