
export class AddRequestFormCommissionDto {
    RequestFormGUID: string;
    PersonnelPostGUID: string;
}

export class RequestFormCommissionDto {
    GUID: string;
    RequestFormGUID: string;
    Description: string;
    Result: string;
    isAccepted: boolean;
    isIgnored: boolean;
    isAcceptedWithCondition: boolean;
    tempisAcceptedWithCondition: boolean;
}


export class RequestFormCommissionCompleteDto {
    GUID: string;
    Result: string;
    isAccepted: boolean;
    isIgnored: boolean;
}




export class List_RequestFormCommissionDto {
    GUID: string;
    RequestFormGUID: string;
    PersonnelPostGUID: string;
    PersonnelPostName: string;
    Description: string;
    isAccepted: boolean;
    isIgnored: boolean;
    isAcceptedWithCondition: boolean;
    tempisAcceptedWithCondition: boolean;



}
export class PList_RequestFormCommissionDto {
    Data: List_RequestFormCommissionDto[];
    TotalCount: number;
}
