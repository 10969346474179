import { AfterViewInit, Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {  List_SoftwareDto, List_UserPostDto } from 'src/app/components/Index';
import { SoftwareService, UserService } from 'src/app/components/ServiceIndex';
import { UserInfo } from 'src/app/_models';
import { environment } from 'src/environments/environment';
import { UserProfileComponent } from 'src/app/elements/header/user-profile/user-profile.component'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { Router } from '@angular/router';
import { PostHandlerService } from 'src/app/_services/post-handler.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
	@ViewChild('UserProfileComponent') _UserProfileComponent: UserProfileComponent;
	@ViewChild('UserProfileModal') UserProfileModal: ModalDirective;

	screenHeight: number;
	screenWidth: number;
	toggleChat: boolean = true;
	toggleSingle: boolean = true;
	Username = '';
	UserEmail = '';
	CurrentActiveSoftware = environment.SoftwareGUID;
	myUserPostControl = new UntypedFormControl();

	constructor(
		protected userService: UserService,
		protected postHandlerService: PostHandlerService,
		protected softwareService: SoftwareService,
		private generalService: GeneralService,
		protected router: Router,
	) {

		this.getScreenSize(null);
	}

	AllUserPosts: List_UserPostDto[] = [];
	filteredUserPosts: Observable<List_UserPostDto[]>;

	ngOnInit(): void {
		const currentUser = JSON.parse(String(localStorage.getItem('SetareganUserInfo')));
		if (currentUser) {
			const user = currentUser as UserInfo;
			this.Username = user.Name;
			this.UserEmail = user.Email;
		}
		this.LoadCombo();
	}


	ngAfterViewInit(): void {


		this.filteredUserPosts = this.myUserPostControl.valueChanges
			.pipe(
				map(cUserPost => typeof cUserPost === 'string' ? cUserPost : cUserPost.OfficeName + ' - ' + cUserPost.SubOfficeName + ' - ' + cUserPost.PostName),
				map(cUserPost => cUserPost !== null ? this._filterUserPost(cUserPost) : this.AllUserPosts.slice())
			);
	}
	async LoadUserProfile() {
		this._UserProfileComponent.LoadFormData();
	}

	displayUserPost(cUserPost?: List_UserPostDto): string | undefined {
		return cUserPost ? cUserPost.PostName : undefined;
	}

	private _filterUserPost(UserPostName: string): List_UserPostDto[] {
		const filterValue = UserPostName.toLowerCase();
		return this.AllUserPosts.filter(s => s.PostName.toLowerCase().includes(filterValue));
	}

	async LoadCombo() {
		await this.LoadUserPosts();
	}

	async LoadUserPosts() {
		const ActivePostGUID = String(localStorage.getItem('ActivePostGUID'));
		const ActivePersonnelPostGUID = String(localStorage.getItem('ActivePersonnelPostGUID'));
		this.AllUserPosts = await this.userService.Get_AllAccessPost()

		if (this.AllUserPosts?.length > 0) {


			 if (this.generalService.CheckValidGUID(ActivePostGUID)) {
			 	const cUserPost = this.AllUserPosts.filter(x => x.PostGUID == ActivePostGUID);
			 	if (this.generalService.CheckArrayHasData(cUserPost) && this.generalService.CheckValidGUID(cUserPost[0].PostGUID)) {
			 		this.myUserPostControl.setValue(cUserPost[0]);
			 		localStorage.setItem('ActivePostGUID', cUserPost[0].PostGUID);
			 		localStorage.setItem('ActivePersonnelPostGUID', cUserPost[0].PersonnelPostGUID);
					 localStorage.setItem('UserGUID',cUserPost[0].UserGUID);
			 		localStorage.setItem('ActivePost', JSON.stringify(cUserPost[0]));
			 		this.postHandlerService.ChangePost_Handler(cUserPost[0].PostGUID);
			 	} else {
			 		localStorage.removeItem('ActivePost');
			 		localStorage.removeItem('ActivePostGUID');
			 		localStorage.removeItem('ActivePersonnelPostGUID');
			 		if (this.generalService.CheckArrayHasData(this.AllUserPosts)) {
			 			this.myUserPostControl.setValue(this.AllUserPosts[0]);
			 			localStorage.setItem('ActivePostGUID', this.AllUserPosts[0].PostGUID);
			 			localStorage.setItem('ActivePersonnelPostGUID', this.AllUserPosts[0].PersonnelPostGUID);
						 localStorage.setItem('UserGUID',this.AllUserPosts[0].UserGUID);
			 			localStorage.setItem('ActivePost', JSON.stringify(this.AllUserPosts[0]));
			 			this.postHandlerService.ChangePost_Handler(this.AllUserPosts[0].PostGUID);
			 		}
			 	}

			 } else {
			 	const cUserPost = this.AllUserPosts[0];
			 	this.myUserPostControl.setValue(cUserPost);
			 	localStorage.setItem('ActivePostGUID', cUserPost.PostGUID);
			 	localStorage.setItem('ActivePersonnelPostGUID', cUserPost.PersonnelPostGUID);
				 localStorage.setItem('UserGUID', cUserPost.UserGUID);
			 	localStorage.setItem('ActivePost', JSON.stringify(cUserPost));
			 	this.postHandlerService.ChangePost_Handler(cUserPost.PostGUID);
			 }
		}

	}

	onSelectedUserPost(isSelected: boolean, UserPost: List_UserPostDto): void {
		 if (isSelected) {
		 	localStorage.setItem('ActivePostGUID', UserPost.PostGUID);
		 	localStorage.setItem('ActivePersonnelPostGUID', UserPost.PersonnelPostGUID);
		 	localStorage.setItem('UserGUID', UserPost.UserGUID);
		 	localStorage.setItem('ActivePost', JSON.stringify(UserPost));
		 	this.postHandlerService.ChangePost_Handler(UserPost.PostGUID);
		 }
	}

	togglechatbar() {
		this.toggleChat = !this.toggleChat;
	}
	singleChatWindow() {
		this.toggleSingle = !this.toggleSingle;
	}
	@HostListener('window:resize', ['$event'])
	getScreenSize(event?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}
	LogOut() {
		localStorage.clear();
		this.router.navigate(['/login']);
	}

}
