

export class SoldierTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_SoldierTypeDto {
    GUID: string;
    Name: string;

}


export class List_SoldierTypeDto {
    GUID: string;
    Name: string;

}
export class PList_SoldierTypeDto {
Data: List_SoldierTypeDto[];
TotalCount: number;
}
