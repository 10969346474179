export enum AlertIconType {
    happy,
    thumb,
    check,
    info,
    warn,
    cancel
}


export enum AlertType {
    success="success",
    info="info",
    warning="warning",
    danger="danger",
    primary="primary",
    secondary="secondary",
    light="light",
    dark="dark"
}