import { environment } from '../../../environments/environment.prod';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IFormCRUD } from 'src/app/_models/interface/IFormCRUD.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth.service';
import { LangService } from 'src/app/shared/lang.service';
//Romove Blow
//import { promise } from 'selenium-webdriver';
import { FormMode } from 'src/app/_models/Global.model';
import { GeneralService } from '../general-service/general.service';


//TODO: این اضافه شود
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html'
})
export class BaseFormComponent implements OnInit, AfterViewInit, OnDestroy, IFormCRUD {
  //#region  form
  myForm: UntypedFormGroup;
  myFormMode: FormMode = FormMode.ReadOnly;
  SpinStatus = false;
  private ComponentName: string = '';
  //#endregion

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected generalService: GeneralService,
    @Inject(String) _ComponentName: string) {
    this.ComponentName = _ComponentName;
  }

  //TODO: Move to base service
  refreshPage(): void {
    window.location.reload();
  }
  //TODO: Move to base service
  async ConfirmDialog1(Title = '',
    MessageBody = '',
    Icon: SweetAlertIcon = 'warning') {

    const Yes = this.langService.TranslateKey("General.Yes");
    const Cancel = this.langService.TranslateKey("General.Cancel");

    let ToReturn = false;

    await Swal.fire({
      title: Title,
      text: MessageBody,
      icon: Icon,
      showCancelButton: true,
      confirmButtonText: Yes,
      cancelButtonText: Cancel
    }).then((Continue) => {
      if (Continue.value) {
        ToReturn = true;
      }
      else {
        ToReturn = false;
      }
    });
    return ToReturn;
  }

  async Cancel(DirtyConfirmClose: boolean = true): Promise<boolean> {
    const TitleText = this.langService.TranslateKey("Message.FormConfirmTitle");
    const MessageText = this.langService.TranslateKey("Message.FormConfirm");
    const Yes = this.langService.TranslateKey("General.Yes");
    const Cancel = this.langService.TranslateKey("General.Cancel");
    let ToReturn = false;
    if (DirtyConfirmClose) {
      ToReturn = Boolean(await this.ConfirmDialog1(TitleText, MessageText));
    }
    return ToReturn;
  }

  doLoadData(GUID: any): void {

  }
  doNewForm(): void {
    this.ClearForm();
  }
  ngOnDestroy(): void {
    this.myFormMode = FormMode.ReadOnly;
  }
  async Initial() {
    await this.getPermisson()
  }

  async getPermisson() {
    const ActivePostGUID = localStorage.getItem('ActivePostGUID');
    if (ActivePostGUID && ActivePostGUID !== undefined && ActivePostGUID !== null && ActivePostGUID !== environment.EmptyGUID) {
      this.GlobalPermissions = await this.generalService.SetBusinessPermisson(this.ComponentName, ActivePostGUID);
      this.BusinessPermisson = await this.generalService.SetGlobalPermission(this.ComponentName, ActivePostGUID);
    }
  }

  ClearForm() {
    this.getPermisson();
    this.myForm.reset();
    this.myForm.controls.GUID.setValue(environment.EmptyGUID);
    this.SpinStatus = false;
    this.myFormMode = FormMode.Insert;
  }


  //#region Permisson lists
  GlobalPermissions: string[] = [];
  BusinessPermisson: string[] = [];
  //#endregion

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {

  }


  GetGlobalPermissionValue(KeyParam: string): boolean {
    return this.generalService.GetPermissionValue(this.GlobalPermissions, KeyParam);
  }

  GetBusinessPermissionValue(KeyParam: string): boolean {
    return this.generalService.GetPermissionValue(this.GlobalPermissions, KeyParam);
  }

  FormValidator(canContinue: boolean = true): string {
    return '';
  }
}
