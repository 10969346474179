
export class AddRequestFormCommissionDetailDto {
    RequestFormGUID: string;
    PersonnelPostGUID: string;
}

export class RequestFormCommissionDetailDto {
    RequestFormCommissionGUID: string;
    PersonnelPostGUID: string;
}

export class Change_RequestFormCommissionDetailDto {
    GUID: string;
    Description: string;
    isAccepted: boolean;
    isIgnored: boolean;
    isAcceptedWithCondition: boolean;
}


export class List_RequestFormCommissionDetailDto {
    GUID: string;
    RequestFormGUID: string;
    PersonnelPostGUID: string;
    PersonnelPostName: string;
    Description: string;
    isAccepted: boolean;
    isIgnored: boolean;
    isAcceptedWithCondition: boolean;
    _isAcceptedWithCondition: boolean;

}
export class PList_RequestFormCommissionDetailDto {
    Data: List_RequestFormCommissionDetailDto[];
    TotalCount: number;
}
