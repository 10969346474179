import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { StartupTypeDto, Combo_StartupTypeDto, List_StartupTypeDto, PList_StartupTypeDto} from './startup-type.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "StartupTypeService"


@Injectable({
 providedIn: 'root',
})
export class StartupTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<StartupTypeDto> {
   let returnObject = new StartupTypeDto()
   await this.RepositoryService.getItemAsync('StartupType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<StartupTypeDto> {
   let returnObject = new StartupTypeDto()
   await this.RepositoryService.getItemAsync('StartupType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_StartupType) => {
           returnObject = _StartupType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_StartupTypeDto>
{
   let returnList = new PList_StartupTypeDto();
   await this.RepositoryService.getAllAsync('StartupType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_StartupTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_StartupTypeDto> {
       let returnList = new PList_StartupTypeDto();
     await  this.RepositoryService.getAllAsync('StartupType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_StartupTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_StartupTypeDto: StartupTypeDto): Promise<StartupTypeDto> {
           let returnvalue: StartupTypeDto = null;
           await this.RepositoryService.createAsync('StartupType/Create_Custom', _StartupTypeDto)
             .then((res) => {
                 returnvalue = res as StartupTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_StartupTypeDto: StartupTypeDto): Promise<StartupTypeDto> {
               let returnvalue: StartupTypeDto = null;
               await this.RepositoryService.updateAsync('StartupType/Update_Custom', _StartupTypeDto)
                 .then((res) => {
                     returnvalue = res as StartupTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('StartupType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_StartupTypeDto[]> {
 let returnvalue: Combo_StartupTypeDto[] = [];
         await this.RepositoryService.getAllAsync('StartupType/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_StartupTypeDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
