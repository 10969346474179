import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { OwnerTypeDto, Combo_OwnerTypeDto, List_OwnerTypeDto, PList_OwnerTypeDto} from './owner-type.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "OwnerTypeService"


@Injectable({
 providedIn: 'root',
})
export class OwnerTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<OwnerTypeDto> {
   let returnObject = new OwnerTypeDto()
   await this.RepositoryService.getItemAsync('OwnerType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<OwnerTypeDto> {
   let returnObject = new OwnerTypeDto()
   await this.RepositoryService.getItemAsync('OwnerType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_OwnerType) => {
           returnObject = _OwnerType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_OwnerTypeDto>
{
   let returnList = new PList_OwnerTypeDto();
   await this.RepositoryService.getAllAsync('OwnerType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_OwnerTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_OwnerTypeDto> {
       let returnList = new PList_OwnerTypeDto();
     await  this.RepositoryService.getAllAsync('OwnerType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_OwnerTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_OwnerTypeDto: OwnerTypeDto): Promise<OwnerTypeDto> {
           let returnvalue: OwnerTypeDto = null;
           await this.RepositoryService.createAsync('OwnerType/Create_Custom', _OwnerTypeDto)
             .then((res) => {
                 returnvalue = res as OwnerTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_OwnerTypeDto: OwnerTypeDto): Promise<OwnerTypeDto> {
               let returnvalue: OwnerTypeDto = null;
               await this.RepositoryService.updateAsync('OwnerType/Update_Custom', _OwnerTypeDto)
                 .then((res) => {
                     returnvalue = res as OwnerTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('OwnerType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_OwnerTypeDto[]> {
 let returnvalue: Combo_OwnerTypeDto[] = [];
         await this.RepositoryService.getAllAsync('OwnerType/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_OwnerTypeDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
