import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from 'src/app/shared/error.service';
import { Injectable } from '@angular/core';


import { ContactManagerInOtherCompanyDto, Combo_ContactManagerInOtherCompanyDto, List_ContactManagerInOtherCompanyDto, PList_ContactManagerInOtherCompanyDto} from './contact-manager-in-other-company.model'



const serviceName= "ContactManagerInOtherCompanyService"


@Injectable({
 providedIn: 'root',
})
export class ContactManagerInOtherCompanyService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<ContactManagerInOtherCompanyDto> {
   let returnObject = new ContactManagerInOtherCompanyDto()
   await this.RepositoryService.getItemAsync('ContactManagerInOtherCompany/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<ContactManagerInOtherCompanyDto> {
   let returnObject = new ContactManagerInOtherCompanyDto()
   await this.RepositoryService.getItemAsync('ContactManagerInOtherCompany/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_ContactManagerInOtherCompany) => {
           returnObject = _ContactManagerInOtherCompany;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_ContactManagerInOtherCompanyDto>
{
   let returnList = new PList_ContactManagerInOtherCompanyDto();
   await this.RepositoryService.getAllAsync('ContactManagerInOtherCompany/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_ContactManagerInOtherCompanyDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(ContactGUID: string ,pagingString = ''): Promise<PList_ContactManagerInOtherCompanyDto> {
       let returnList = new PList_ContactManagerInOtherCompanyDto();
     await  this.RepositoryService.getAllAsync('ContactManagerInOtherCompany/Get_All(ContactGUID='+ContactGUID+')' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_ContactManagerInOtherCompanyDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_ContactManagerInOtherCompanyDto: ContactManagerInOtherCompanyDto): Promise<ContactManagerInOtherCompanyDto> {
           let returnvalue: ContactManagerInOtherCompanyDto = null;
           await this.RepositoryService.createAsync('ContactManagerInOtherCompany/Create_Custom', _ContactManagerInOtherCompanyDto)
             .then((res) => {
                 returnvalue = res as ContactManagerInOtherCompanyDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_ContactManagerInOtherCompanyDto: ContactManagerInOtherCompanyDto): Promise<ContactManagerInOtherCompanyDto> {
               let returnvalue: ContactManagerInOtherCompanyDto = null;
               await this.RepositoryService.updateAsync('ContactManagerInOtherCompany/Update_Custom', _ContactManagerInOtherCompanyDto)
                 .then((res) => {
                     returnvalue = res as ContactManagerInOtherCompanyDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('ContactManagerInOtherCompany/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }
}
