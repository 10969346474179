

export class MasterStatusTashilatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_MasterStatusTashilatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_MasterStatusTashilatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_MasterStatusTashilatTypeDto {
Data: List_MasterStatusTashilatTypeDto[];
TotalCount: number;
}
