

export class ExpiredZemanatnamehTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_ExpiredZemanatnamehTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_ExpiredZemanatnamehTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_ExpiredZemanatnamehTypeDto {
Data: List_ExpiredZemanatnamehTypeDto[];
TotalCount: number;
}
