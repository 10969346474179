<!--**********************************
    Footer start
***********************************-->
<div class="footer">
    <div class="copyright">
        <!-- <p>طراحی با <span style="color:red"> 
        
            <span [ngClass]="{'': toggleIcon, 'heart-blast': !toggleIcon}"
                                        class="heart" (click)="toggleLoveIcon()"></span>
                                        
        </span> در انفورماتیک </p> -->
    </div>
</div>
<!--**********************************
    Footer end
***********************************-->