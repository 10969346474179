

export class FlowStepDto {
    GUID: string;
    FlowID: number;
    FlowGUID: string;
    TaskActionID: number;
    TaskActionGUID: string;
    Name: string;

}


export class Combo_FlowStepDto {
    GUID: string;
    FlowGUID: string;
    FlowName: string;
    TaskActionGUID: string;
    TaskActionName: string;
    Name: string;

}


export class List_FlowStepDto {
    GUID: string;
    FlowGUID: string;
    FlowName: string;
    TaskActionGUID: string;
    TaskActionName: string;
    Name: string;

}
export class PList_FlowStepDto {
Data: List_FlowStepDto[];
TotalCount: number;
}
