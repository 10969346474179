

export class ContactManagerInOtherCompanyDto {
    GUID: string;
    ContactID: number;
    ContactGUID: string;
    Name: string;
    CompanyName: string;
    Post: string;
    SahrePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class Combo_ContactManagerInOtherCompanyDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    CompanyName: string;
    Post: string;
    SahrePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class List_ContactManagerInOtherCompanyDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    CompanyName: string;
    Post: string;
    SahrePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}
export class PList_ContactManagerInOtherCompanyDto {
Data: List_ContactManagerInOtherCompanyDto[];
TotalCount: number;
}
