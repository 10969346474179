import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from 'src/app/shared/error.service';
import { Injectable } from '@angular/core';


import { ContactManagerDto, Combo_ContactManagerDto, List_ContactManagerDto, PList_ContactManagerDto} from './contact-manager.model'



const serviceName= "ContactManagerService"


@Injectable({
 providedIn: 'root',
})
export class ContactManagerService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<ContactManagerDto> {
   let returnObject = new ContactManagerDto()
   await this.RepositoryService.getItemAsync('ContactManager/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<ContactManagerDto> {
   let returnObject = new ContactManagerDto()
   await this.RepositoryService.getItemAsync('ContactManager/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_ContactManager) => {
           returnObject = _ContactManager;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_ContactManagerDto>
{
   let returnList = new PList_ContactManagerDto();
   await this.RepositoryService.getAllAsync('ContactManager/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_ContactManagerDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(ContactGUID: string ,  pagingString = ''): Promise<PList_ContactManagerDto> {
       let returnList = new PList_ContactManagerDto();
     await  this.RepositoryService.getAllAsync('ContactManager/Get_All(ContactGUID='+ContactGUID+')' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_ContactManagerDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



       async  Get_AllForInqueryList(ContactGUID: string  , RequestFormGUID: string,  pagingString = ''): Promise<PList_ContactManagerDto> {
        let returnList = new PList_ContactManagerDto();
      await  this.RepositoryService.getAllAsync('ContactManager/Get_AllForInqueryList(ContactGUID='+ContactGUID+',RequestFormGUID='+RequestFormGUID+')' + pagingString)
          .then((res) => {
              returnList.Data = res['value'] as List_ContactManagerDto[];
              returnList.TotalCount = res['@odata.count'] as number;
          }).catch (
        (error) => {
            this.errorService.Failed(error);
            return undefined;
        }
      );
        return returnList;
        }
 

        

 async Create_Custom(_ContactManagerDto: ContactManagerDto): Promise<ContactManagerDto> {
           let returnvalue: ContactManagerDto = null;
           await this.RepositoryService.createAsync('ContactManager/Create_Custom', _ContactManagerDto)
             .then((res) => {
                 returnvalue = res as ContactManagerDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_ContactManagerDto: ContactManagerDto): Promise<ContactManagerDto> {
               let returnvalue: ContactManagerDto = null;
               await this.RepositoryService.updateAsync('ContactManager/Update_Custom', _ContactManagerDto)
                 .then((res) => {
                     returnvalue = res as ContactManagerDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('ContactManager/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }
}
