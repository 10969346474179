

export class VasigheTypeDto {
    GUID: string;
    CompanyRange: number;
    Name: string;
    PercentValueType1: number;
    PercentValueType2: number;

}


export class Combo_VasigheTypeDto {
    GUID: string;
    CompanyRange: number;
    Name: string;
    PercentValueType1: number;
    PercentValueType2: number;

}


export class List_VasigheTypeDto {
    GUID: string;
    CompanyRange: number;
    Name: string;
    PercentValueType1: number;
    PercentValueType2: number;

}
export class PList_VasigheTypeDto {
Data: List_VasigheTypeDto[];
TotalCount: number;
}
