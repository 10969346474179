

export class FlowStepArrangeDto {
    GUID: string;
    PostID: number;
    PostGUID: string;
    FlowStepID: number;
    FlowStepGUID: string;
    isStart: boolean;

}


export class Combo_FlowStepArrangeDto {
    GUID: string;
    PostGUID: string;
    PostName: string;
    FlowStepGUID: string;
    FlowStepName: string;
    isStart: boolean;

}


export class List_FlowStepArrangeDto {
    GUID: string;
    PostGUID: string;
    PostName: string;
    FlowStepGUID: string;
    FlowStepName: string;
    isStart: boolean;

}
export class PList_FlowStepArrangeDto {
Data: List_FlowStepArrangeDto[];
TotalCount: number;
}
