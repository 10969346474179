

export class ContactManagerImmovableDto {
    GUID: string;
    ContactID: number;
    ContactGUID: string;
    ImmovableTypeID: number;
    ImmovableTypeGUID: string;
    Name: string;
    RegisterNO: string;
    UseType: string;
    Area: number;
    CurrentCost: number;
    SahrePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class Combo_ContactManagerImmovableDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    ImmovableTypeGUID: string;
    ImmovableTypeName: string;
    Name: string;
    RegisterNO: string;
    UseType: string;
    Area: number;
    CurrentCost: number;
    SahrePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class List_ContactManagerImmovableDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    ImmovableTypeGUID: string;
    ImmovableTypeName: string;
    Name: string;
    RegisterNO: string;
    UseType: string;
    Area: number;
    CurrentCost: number;
    SahrePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}
export class PList_ContactManagerImmovableDto {
Data: List_ContactManagerImmovableDto[];
TotalCount: number;
}
