

export class PersonnelPostDto {
    GUID: string;
    PersonnelID: number;
    PersonnelGUID: string;
    PostID: number;
    PostGUID: string;
    StartDate: string;
    StartDateShamsi: string;
    EndDate: string;
    EndDateShamsi: string;

}

export class  Combo_PersonnelPostDto{
    GUID: string;
    Name: string;

}

export class List_PersonnelPostDto {
    GUID: string;
    PersonnelGUID: string;
    PersonnelName: string;
    PostGUID: string;
    PostName: string;
    StartDate: string;
    StartDateShamsi: string;
    EndDate: string;
    EndDateShamsi: string;

}
export class PList_PersonnelPostDto {
Data: List_PersonnelPostDto[];
TotalCount: number;
}
