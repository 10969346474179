import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { ContactDto, Combo_ContactDto, List_ContactDto, PList_ContactDto } from './contact.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName = "ContactService"


@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<ContactDto> {
    let returnObject = new ContactDto()
    await this.RepositoryService.getItemAsync('Contact/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<ContactDto> {
    let returnObject = new ContactDto()
    await this.RepositoryService.getItemAsync('Contact/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Contact) => {
          returnObject = _Contact;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_ContactDto> {
    let returnList = new PList_ContactDto();
    await this.RepositoryService.getAllAsync('Contact/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ContactDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_ContactDto> {
    let returnList = new PList_ContactDto();
    await this.RepositoryService.getAllAsync('Contact/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ContactDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_ContactDto: ContactDto): Promise<ContactDto> {
    let returnvalue: ContactDto = null;
    await this.RepositoryService.createAsync('Contact/Create_Custom', _ContactDto)
      .then((res) => {
        returnvalue = res as ContactDto;
        this.errorService.Success(1)
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_ContactDto: ContactDto): Promise<ContactDto> {
    let returnvalue: ContactDto = null;
    await this.RepositoryService.updateAsync('Contact/Update_Custom', _ContactDto)
      .then((res) => {
        returnvalue = res as ContactDto;
        this.errorService.Success(2)
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Contact/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
        this.errorService.Error(1);

      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async LoadCombo(): Promise<Combo_ContactDto[]> {
    let returnvalue: Combo_ContactDto[] = [];
    await this.RepositoryService.getAllAsync('Contact/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_ContactDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

}
