

export class RequestTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_RequestTypeDto {
    GUID: string;
    Name: string;

}


export class List_RequestTypeDto {
    GUID: string;
    Name: string;

}
export class PList_RequestTypeDto {
Data: List_RequestTypeDto[];
TotalCount: number;
}
