import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { SoldierTypeDto, Combo_SoldierTypeDto, List_SoldierTypeDto, PList_SoldierTypeDto} from './soldier-type.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "SoldierTypeService"


@Injectable({
 providedIn: 'root',
})
export class SoldierTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<SoldierTypeDto> {
   let returnObject = new SoldierTypeDto()
   await this.RepositoryService.getItemAsync('SoldierType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<SoldierTypeDto> {
   let returnObject = new SoldierTypeDto()
   await this.RepositoryService.getItemAsync('SoldierType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_SoldierType) => {
           returnObject = _SoldierType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_SoldierTypeDto>
{
   let returnList = new PList_SoldierTypeDto();
   await this.RepositoryService.getAllAsync('SoldierType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_SoldierTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_SoldierTypeDto> {
       let returnList = new PList_SoldierTypeDto();
     await  this.RepositoryService.getAllAsync('SoldierType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_SoldierTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_SoldierTypeDto: SoldierTypeDto): Promise<SoldierTypeDto> {
           let returnvalue: SoldierTypeDto = null;
           await this.RepositoryService.createAsync('SoldierType/Create_Custom', _SoldierTypeDto)
             .then((res) => {
                 returnvalue = res as SoldierTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_SoldierTypeDto: SoldierTypeDto): Promise<SoldierTypeDto> {
               let returnvalue: SoldierTypeDto = null;
               await this.RepositoryService.updateAsync('SoldierType/Update_Custom', _SoldierTypeDto)
                 .then((res) => {
                     returnvalue = res as SoldierTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('SoldierType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

                   async LoadCombo(): Promise<Combo_SoldierTypeDto[]> {
                    let returnvalue: Combo_SoldierTypeDto[] = [];
                            await this.RepositoryService.getAllAsync('SoldierType/Get_Combo')
                              .then((res) => {
                                  returnvalue = res['value'] as Combo_SoldierTypeDto[];
                              }).catch (
                        (error) => {
                                this.errorService.Failed(error);
                            });
                            return returnvalue;
                            }
}
