

export class HRStabilityTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_HRStabilityTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_HRStabilityTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_HRStabilityTypeDto {
Data: List_HRStabilityTypeDto[];
TotalCount: number;
}
