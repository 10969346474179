

export class ContactDto {
    GUID: string;
    Name: string;
    FoundDate: string;
    FoundDateShamsi: string;
    RegistrationNO: string;
    RegistrationLocation: string;
    StartActivityDate: string;
    StartActivityDateShamsi: string;
    ActivityTypeID: number;
    ActivityTypeGUID: string;
    ActivityTypeDescription: string;
    ActivityDescription: string;
    FirstFund: number;
    CenterOfficeAddres: string;
    CenterOfficeTell: string;
    CenterOfficeFax: string;
    CenterOfficePostalCode: string;
    CenterOfficeOwnerTypeID: number;
    CenterOfficeOwnerTypeGUID: string;
    CenterOfficeOwnerTypeDescription: string;
    CenterOfficeCurrentCost: number;
    CompanyAddres: string;
    CompanyTell: string;
    CompanyFax: string;
    CompanyOwnerTypeID: number;
    CompanyOwnerTypeGUID: string;
    CompanyOwnerTypeDescription: string;
    CompanyCurrentCost: number;
    StockAddres: string;
    StockTell: string;
    StockFax: string;
    StockOwnerTypeID: number;
    StockOwnerTypeGUID: string;
    StockOwnerTypeDescription: string;
    StockCurrentCost: number;

}


export class Combo_ContactDto {
    GUID: string;
    Name: string;
    FoundDate: string;
    FoundDateShamsi: string;
    RegistrationNO: string;
    RegistrationLocation: string;
    StartActivityDate: string;
    StartActivityDateShamsi: string;
    ActivityTypeGUID: string;
    ActivityTypeName: string;
    ActivityTypeDescription: string;
    ActivityDescription: string;
    FirstFund: number;
    CenterOfficeAddres: string;
    CenterOfficeTell: string;
    CenterOfficeFax: string;
    CenterOfficePostalCode: string;
    CenterOfficeOwnerTypeGUID: string;
    CenterOfficeOwnerTypeName: string;
    CenterOfficeOwnerTypeDescription: string;
    CenterOfficeCurrentCost: number;
    CompanyAddres: string;
    CompanyTell: string;
    CompanyFax: string;
    CompanyOwnerTypeGUID: string;
    CompanyOwnerTypeName: string;
    CompanyOwnerTypeDescription: string;
    CompanyCurrentCost: number;
    StockAddres: string;
    StockTell: string;
    StockFax: string;
    StockOwnerTypeGUID: string;
    StockOwnerTypeName: string;
    StockOwnerTypeDescription: string;
    StockCurrentCost: number;

}


export class List_ContactDto {
    GUID: string;
    Name: string;
    FoundDate: string;
    FoundDateShamsi: string;
    RegistrationNO: string;
    RegistrationLocation: string;
    StartActivityDate: string;
    StartActivityDateShamsi: string;
    ActivityTypeGUID: string;
    ActivityTypeName: string;
    ActivityTypeDescription: string;
    ActivityDescription: string;
    FirstFund: number;
    CenterOfficeAddres: string;
    CenterOfficeTell: string;
    CenterOfficeFax: string;
    CenterOfficePostalCode: string;
    CenterOfficeOwnerTypeGUID: string;
    CenterOfficeOwnerTypeName: string;
    CenterOfficeOwnerTypeDescription: string;
    CenterOfficeCurrentCost: number;
    CompanyAddres: string;
    CompanyTell: string;
    CompanyFax: string;
    CompanyOwnerTypeGUID: string;
    CompanyOwnerTypeName: string;
    CompanyOwnerTypeDescription: string;
    CompanyCurrentCost: number;
    StockAddres: string;
    StockTell: string;
    StockFax: string;
    StockOwnerTypeGUID: string;
    StockOwnerTypeName: string;
    StockOwnerTypeDescription: string;
    StockCurrentCost: number;

}
export class PList_ContactDto {
Data: List_ContactDto[];
TotalCount: number;
}
