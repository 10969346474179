

export class RoleDto {
    GUID: string;
    SoftwareID: number;
    SoftwareGUID: string;
    Name: string;

}


export class Combo_RoleDto {
    GUID: string;
    SoftwareGUID: string;
    SoftwareName: string;
    Name: string;

}


export class List_RoleDto {
    GUID: string;
    SoftwareGUID: string;
    SoftwareName: string;
    Name: string;

}
export class PList_RoleDto {
Data: List_RoleDto[];
TotalCount: number;
}


export class Filter_RoleDto {
    SoftwareGUIDs: string[];
    Name: string;
    }