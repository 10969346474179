

export class ContactOwnerDto {
    GUID: string;
    ContactID: number;
    ContactGUID: string;
    Name: string;
    FatherName: string;
    RegisterNO: string;
    RegisterLocation: string;
    NationalCode: string;
    SharePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class Combo_ContactOwnerDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    FatherName: string;
    RegisterNO: string;
    RegisterLocation: string;
    NationalCode: string;
    SharePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class List_ContactOwnerDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    FatherName: string;
    RegisterNO: string;
    RegisterLocation: string;
    NationalCode: string;
    SharePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}
export class PList_ContactOwnerDto {
Data: List_ContactOwnerDto[];
TotalCount: number;
}
