

export class LastStatusTaahodPardakhtTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_LastStatusTaahodPardakhtTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_LastStatusTaahodPardakhtTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_LastStatusTaahodPardakhtTypeDto {
Data: List_LastStatusTaahodPardakhtTypeDto[];
TotalCount: number;
}
