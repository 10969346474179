

export class ZaribTypeDto {
    GUID: string;
    CompanyRange: number;
    Name: string;
    PercentValueType1: number;
    PercentValueType2: number;

}


export class Combo_ZaribTypeDto {
    GUID: string;
    CompanyRange: number;
    Name: string;
    PercentValueType1: number;
    PercentValueType2: number;

}


export class List_ZaribTypeDto {
    GUID: string;
    CompanyRange: number;
    Name: string;
    PercentValueType1: number;
    PercentValueType2: number;

}
export class PList_ZaribTypeDto {
Data: List_ZaribTypeDto[];
TotalCount: number;
}
