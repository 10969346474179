

export class GenderDto {
    GUID: string;
    Name: string;

}


export class Combo_GenderDto {
    GUID: string;
    Name: string;

}


export class List_GenderDto {
    GUID: string;
    Name: string;

}
export class PList_GenderDto {
Data: List_GenderDto[];
TotalCount: number;
}
