

export class CompanyTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_CompanyTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_CompanyTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_CompanyTypeDto {
Data: List_CompanyTypeDto[];
TotalCount: number;
}
