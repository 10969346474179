

export class ContactCompanyDto {
    GUID: string;
    ContactID: number;
    ContactGUID: string;
    Name: string;
    CompanyTypeID: number;
    CompanyTypeGUID: string;
    CompanyActivity: string;
    AllBudget: number;
    SahrePercent: number;
    RealCost: number;
    StockCost: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class Combo_ContactCompanyDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    CompanyTypeGUID: string;
    CompanyTypeName: string;
    CompanyActivity: string;
    AllBudget: number;
    SahrePercent: number;
    RealCost: number;
    StockCost: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class List_ContactCompanyDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    CompanyTypeGUID: string;
    CompanyTypeName: string;
    CompanyActivity: string;
    AllBudget: number;
    SahrePercent: number;
    RealCost: number;
    StockCost: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}
export class PList_ContactCompanyDto {
Data: List_ContactCompanyDto[];
TotalCount: number;
}
