import { List_ContactManagerInqueryDto } from "../../base/contact-manager/contact-manager.model";


export class RequestFormDto {
    ID: number;
    GUID: string;
    ContactGUID: string;
    RequestTypeGUID: string;
    ExteraNote: number;
    RequestDate: string;
    isSendToForCommission: boolean;
    isCommissionDone: boolean;
    isPaymentDone: boolean;
    isPayment: boolean;

    CurrentStepPersonnelPostGUID: string;
    CurrentStepUserGUID: string;
}



export class RequestFormDetailDto {
    ID: number;
    GUID: string;
    isChequeRequest: boolean;
    ChequeRequestNO: number;
    ChequeRequestCost: number;
    isBillRequest: boolean;
    BillRequestNO: number;
    BillRequestCost: number;
    ManifestTypeGUID: string;
    AuditorTypeGUID: string;
    StartupTypeGUID: string;
    ActivityTypeGUID: string;
    CompanyTypeGUID: string;
    CompanyAgeGUID: string;
    HRStabilityTypeGUID: string;
    LastZemanatTypeGUID: string;
    LastTashilatTypeGUID: string;
    KnowledgeTypeGUID: string;
    KnowledgeTypeSelected: number;
    ExpertOpinionTypeGUID: string;
    ManagerResumeTypeGUID: string;
    ExpiredZemanatnamehTypeGUID: string;
    StockTypeGUID: string;
    StockTypeSelected: number;
    MasterStatusZemanatTypeGUID: string;
    MasterStatusTashilatTypeGUID: string;
    LastStatusZemanatTypeGUID: string;
    LastStatusTaahodPardakhtTypeGUID: string;
    Year1: number;
    Year1Zarib: number;
    Year2: number;
    Year2Zarib: number;
    Year3: number;
    Year3Zarib: number;
    CompanyEvaluationScore: number;

    TotalAccountingTitleValue322: number;
    TotalAccountingTitleValue323: number;
    TotalAccountingTitleValue324: number;
    TotalAccountingTitleValue325: number;
    TotalAccountingTitleValue326: number;
    TotalAccountingTitleValue327: number;

    ContactManagerInqueries: List_ContactManagerInqueryDto[];
}


export class RequestFormPaymentDto {
    GUID: string;
    PaymentTypeGUID: string;
}


export class RequestFormFlowDto {
    GUID: string;
    PersonnelPostUserGUID: string;
    ConditionID: number;
    Description: string;
}




export class List_RequestFormDto {
    ID: number;
    GUID: string;
    ContactGUID: string;
    RequestDateShamsi: number;
    RequestTypeGUID: string;
    RequestStatusTypeGUID: string;

    isNew: boolean;
    isDone: boolean;
    isSendToForCommission: boolean;
    CommissionResult: boolean;

    PersonnelPostGUID: string;
    ContactName: string;
    RequestTypeName: string;
    RequestStatusTypeName: string;

}
export class PList_RequestFormDto {
    Data: List_RequestFormDto[];
    TotalCount: number;
}




export class DownloadDto {
    GUID: string;
    RequestFormGUID: string;
    FileTypeGUID: string;

}