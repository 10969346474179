import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { RequestFormCommissionDetailDto, List_RequestFormCommissionDetailDto, PList_RequestFormCommissionDetailDto, Change_RequestFormCommissionDetailDto} from './request-form-commission-detail.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "RequestFormCommissionDetailService"


@Injectable({
 providedIn: 'root',
})
export class RequestFormCommissionDetailService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(RequestFormGUID = ''): Promise<RequestFormCommissionDetailDto> {
   let returnObject = new RequestFormCommissionDetailDto()
   await this.RepositoryService.getItemAsync('RequestFormCommissionDetail/Get_Item(RequestFormGUID='+ RequestFormGUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<RequestFormCommissionDetailDto> {
   let returnObject = new RequestFormCommissionDetailDto()
   await this.RepositoryService.getItemAsync('RequestFormCommissionDetail/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_RequestFormCommissionDetail) => {
           returnObject = _RequestFormCommissionDetail;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_RequestFormCommissionDetailDto>
{
   let returnList = new PList_RequestFormCommissionDetailDto();
   await this.RepositoryService.getAllAsync('RequestFormCommissionDetail/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_RequestFormCommissionDetailDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(RequestFormCommissionGUID: string , ActivePersonnelPostGUID: string  ,pagingString = ''): Promise<PList_RequestFormCommissionDetailDto> {
       let returnList = new PList_RequestFormCommissionDetailDto();
     await  this.RepositoryService.getAllAsync('RequestFormCommissionDetail/Get_All(RequestFormCommissionGUID='+RequestFormCommissionGUID+',ActivePersonnelPostGUID='+ActivePersonnelPostGUID+')' + pagingString)
         .then((res) => {
           returnList.Data = res['value'] as List_RequestFormCommissionDetailDto[];
           returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_RequestFormCommissionDetailDto: RequestFormCommissionDetailDto): Promise<RequestFormCommissionDetailDto> {
           let returnvalue: RequestFormCommissionDetailDto = null;
           await this.RepositoryService.createAsync('RequestFormCommissionDetail/Create_Custom', _RequestFormCommissionDetailDto)
             .then((res) => {
                 returnvalue = res as RequestFormCommissionDetailDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }



 async Update_Custom(_Change_RequestFormCommissionDetailDto: Change_RequestFormCommissionDetailDto): Promise<RequestFormCommissionDetailDto> {
               let returnvalue: RequestFormCommissionDetailDto = null;
               await this.RepositoryService.updateAsync('RequestFormCommissionDetail/Update_Custom', _Change_RequestFormCommissionDetailDto)
                 .then((res) => {
                     returnvalue = res as RequestFormCommissionDetailDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('RequestFormCommissionDetail/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }


}
