

export class CustomerRankDto {
    GUID: string;
    Name: string;
    RankName: string;
    FromRangeNumber: number;
    ToRangeNumber: number;
    FromRangePercent: number;
    ToRangePercent: number;

}


export class Combo_CustomerRankDto {
    GUID: string;
    Name: string;
    RankName: string;
    FromRangeNumber: number;
    ToRangeNumber: number;
    FromRangePercent: number;
    ToRangePercent: number;

}


export class List_CustomerRankDto {
    GUID: string;
    Name: string;
    RankName: string;
    FromRangeNumber: number;
    ToRangeNumber: number;
    FromRangePercent: number;
    ToRangePercent: number;

}
export class PList_CustomerRankDto {
Data: List_CustomerRankDto[];
TotalCount: number;
}
