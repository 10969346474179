import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { RequestFormFileTypeDto, List_RequestFormFileTypeDto, PList_RequestFormFileTypeDto } from './request-form-file-type.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName = "RequestFormFileTypeService"


@Injectable({
  providedIn: 'root',
})
export class RequestFormFileTypeService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<RequestFormFileTypeDto> {
    let returnObject = new RequestFormFileTypeDto()
    await this.RepositoryService.getItemAsync('RequestFormFileType/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }



  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<RequestFormFileTypeDto> {
    let returnObject = new RequestFormFileTypeDto()
    await this.RepositoryService.getItemAsync('RequestFormFileType/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_RequestFormFileType) => {
          returnObject = _RequestFormFileType;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_RequestFormFileTypeDto> {
    let returnList = new PList_RequestFormFileTypeDto();
    await this.RepositoryService.getAllAsync('RequestFormFileType/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_RequestFormFileTypeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(RequestFormGUID: string, pagingString = ''): Promise<PList_RequestFormFileTypeDto> {
    let returnList = new PList_RequestFormFileTypeDto();
    await this.RepositoryService.getAllAsync('RequestFormFileType/Get_All(RequestFormGUID=' + RequestFormGUID + ')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_RequestFormFileTypeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


  async Confirm(GUID: string): Promise<boolean> {
    let returnList = false;
    await this.RepositoryService.getItemAsync('RequestFormFileType/Confirm(key=' + GUID + ')')
      .then((res) => {
        returnList = res['value'];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }

  async Create_Custom(_RequestFormFileTypeDto: RequestFormFileTypeDto): Promise<RequestFormFileTypeDto> {
    let returnvalue: RequestFormFileTypeDto = null;
    await this.RepositoryService.createAsync('RequestFormFileType/Create_Custom', _RequestFormFileTypeDto)
      .then((res) => {
        returnvalue = res as RequestFormFileTypeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_RequestFormFileTypeDto: RequestFormFileTypeDto): Promise<RequestFormFileTypeDto> {
    let returnvalue: RequestFormFileTypeDto = null;
    await this.RepositoryService.updateAsync('RequestFormFileType/Update_Custom', _RequestFormFileTypeDto)
      .then((res) => {
        returnvalue = res as RequestFormFileTypeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }





  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('RequestFormFileType/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
