

export class RequestFormFileTypeDto {
    GUID: string;
    RequestFormGUID: string;
    FileTypeGUID: string;
    FileTypeName: string;
    Description: string;
    isAccepted: string;
    isNeeded: string;
    GenerateFunction: string;
    ProcessFunction: string;
    StaticFile: string;

}







export class List_RequestFormFileTypeDto {
    GUID: string;
    FileName: string;
    RequestFormGUID: string;
    FileTypeGUID: string;
    FileTypeName: string;
    Description: string;
    isAccepted: string;
    isNeeded: string;
    GenerateFunction: string;
    ProcessFunction: string;
    StaticFile: string;
}
export class PList_RequestFormFileTypeDto {
    Data: List_RequestFormFileTypeDto[];
    TotalCount: number;
}
