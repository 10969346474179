import { environment } from 'src/environments/environment';

import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild, HostListener, ChangeDetectorRef, AfterContentChecked, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LangService } from 'src/app/shared/lang.service';
import { GeneralService } from '../general-service/general.service';
import { AlertType, AlertIconType } from 'src/app/shared/global.enum';

import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';



@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseListComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
  screenHeight: number;
  screenWidth: number;
  TotalHouse: number = 0;
  public AlertIconTypeEnum = AlertIconType;
  public AlertTypeEnum = AlertType;
  //#region Global variables
  DataEntryFormHasError = '';
  ComponentName = '';
  SpinStatus = false;
  myGridFilter: UntypedFormGroup;
  //#endregion

  //#region Permisson lists
  GlobalPermissions: string[] = [];
  BusinessPermisson: string[] = [];
  //#endregion

  //#region Grid variables
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['Name', 'actions'];
  pageSizeOptions = [25, 50, 100];
  dataSource = new MatTableDataSource<any>();
  expandedElement: any;
  expandedMode = false;

  length = 0;
  pageSize = 25;
  pageIndex = 0;
  SortActive = ''
  direction = 'asc';

  //#endregion
  constructor(
    //  private cdr: ChangeDetectorRef,
    router: Router,
    route: ActivatedRoute,
    protected authService: AuthService,
    protected toastr: ToastrService,
    langService: LangService,
    protected generalService: GeneralService,
    @Inject(String) _ComponentName: string
  ) {
    this.ComponentName = _ComponentName;
    this.screenWidth = window.innerWidth;
  }

  ngAfterContentChecked(): void {
    // this.cdr.detectChanges();
  }

  //TODO: اضافه شود
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

  }
  ngOnDestroy(): void { }

  async Initial() {
    await this.getPermisson();
  }

  async getPermisson() {
    const ActivePostGUID = localStorage.getItem('ActivePostGUID');
    if (ActivePostGUID && ActivePostGUID !== undefined && ActivePostGUID !== null && ActivePostGUID !== environment.EmptyGUID) {
      this.GlobalPermissions = await this.generalService.SetBusinessPermisson(this.ComponentName, ActivePostGUID);
      this.BusinessPermisson = await this.generalService.SetGlobalPermission(this.ComponentName, ActivePostGUID);
    }
  }


  applyFilter() {
    this.FillDataGrid();
  }
  FillDataGrid(filter = '') {

  }
  sortData(event: any) {
    this.SortActive = event.active;
    this.direction = event.direction;
    this.applyFilter();
  }

  getNext(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.applyFilter();
  }

  onSearchClear() {
    this.myGridFilter.controls.SearchKey.setValue(null);
    this.FillDataGrid();
  }


  public doRefreshData() {
    this.applyFilter();
  }

  GetGlobalPermissionValue(KeyParam: string): boolean {
    return this.generalService.GetPermissionValue(this.GlobalPermissions, KeyParam);
  }

  GetBusinessPermissionValue(KeyParam: string): boolean {
    return this.generalService.GetPermissionValue(this.GlobalPermissions, KeyParam);
  }


  MakeODataParameter(): string {

    let pagingstr = '?';

    if (this.myGridFilter.controls.SearchKey.value) {
      pagingstr += '$filter=indexof(Name, \'' + this.myGridFilter.controls.SearchKey.value + '\') gt -1   or indexof(CityName, \'' + this.myGridFilter.controls.SearchKey.value + '\') gt -1 ';
    }
    if (pagingstr !== '?') {
      pagingstr += '&';
    }
    if (this.SortActive && this.direction) {
      pagingstr += '$orderby=' + this.SortActive + '%20' + this.direction
    }
    if (pagingstr !== '?') {
      pagingstr += '&';
    }
    pagingstr += '$top=' + this.pageSize + '&$skip=' + (this.pageIndex * this.pageSize)
    pagingstr += '&$count=true'

    return pagingstr;
  }
  CountOfHouse() {
    //this.TotalHouse= this.dataSource?.data?.length;

    if (this.dataSource != null && this.dataSource.data != null) {
      this.TotalHouse = this.dataSource.data.filter((v, i, a) => a.findIndex(t => (t.HouseGUID === v.HouseGUID)) === i).length
    }

  }
  async GlobalHenCountValidation(): Promise<boolean> {
    const HouseHasError = this.dataSource.data.filter(row => row.HenCount > row.HouseHenCount || (row.FlockIsMother && row.RoosterCount > row.HouseRoosterCount)).map(m => m.HouseName).join(', ');

    if (HouseHasError.length > 0) {
      this.toastr.error('درسالنهای ' + HouseHasError + ' مقدار وارد شده از موجودی سالن بیشتر میباشد', ' خطای موجودی');
    }

    return HouseHasError.length > 0;

  }


  async UpdatePermissionCheck(): Promise<boolean> {

    const SaveAccess = this.BusinessPermisson.filter(x => x === this.ComponentName + 'Save').length > 0;
    if (!SaveAccess) {

      for (let index = 0; index < this.dataSource.data.length; index++) {
        this.dataSource.data[index].isLock = true;

      }
    }
    return SaveAccess;
  }


  SortByGpFlockName(unsortedArray: any): any[] {
    var sortedArray: any[] = unsortedArray.sort((obj1, obj2) => {
      if (obj1.GpFlockName > obj2.GpFlockName) {
        return 1;
      }

      if (obj1.GpFlockName < obj2.GpFlockName) {
        return -1;
      }

      return 0;
    });



    return sortedArray;
  }

  SortByHouseName(unsortedArray: any): any[] {
    var sortedArray: any[] = unsortedArray.sort((obj1, obj2) => {
      if (this.generalService.cNumber(String(obj1.HouseName).replace(/\D/g, '')) > this.generalService.cNumber(String(obj2.HouseName).replace(/\D/g, ''))) {
        return 1;
      }

      if (this.generalService.cNumber(String(obj1.HouseName).replace(/\D/g, '')) < this.generalService.cNumber(String(obj2.HouseName).replace(/\D/g, ''))) {
        return -1;
      }
      if (obj1.HouseName > obj2.HouseName) {
        return 1;
      }

      if (obj1.HouseName < obj2.HouseName) {
        return -1;
      }
      return 0;
    });



    return sortedArray;
  }


  SortByHouseNameSiloName(unsortedArray: any): any[] {
    var sortedArray: any[] = unsortedArray.sort((obj1, obj2) => {
      if (obj1.SiloName > obj2.SiloName) {
        return 1;
      }

      if (obj1.SiloName < obj2.SiloName) {
        return -1;
      }

      return 0;
    });



    return sortedArray;
  }

  async ConfirmDialog1(Title = '',
    MessageBody = '', YesCaption = '', CancelCaption = '') {

    const Yes = (YesCaption === '') ? 'بله' : YesCaption;
    const Cancel = (CancelCaption === '') ? 'خیر' : CancelCaption;

    let ToReturn = false;

    await Swal.fire({
      title: Title,
      text: MessageBody,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: Yes,
      cancelButtonText: Cancel
    }).then((Continue) => {
      if (Continue.value) {
        ToReturn = true;
      }
      else {
        ToReturn = false;
      }
    });
    return ToReturn;
  }
  async ConfirmUnSaveDialog(): Promise<boolean> {
    return Boolean(await this.ConfirmDialog1('تایید خروج', 'در این فرم تغییرات داده شد و ذخیره نشده است، آیا واقعا میخواهید خارج شوید؟', 'بله فرم را ببند', 'خیر ادامه میدم'));
  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

  }
}


@Component({
  selector: 'bottom-sheet-componnet',
  templateUrl: './bottom-sheet-componnet.html',
})
export class BottomSheetComponnet {
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponnet>
    , @Inject(MAT_BOTTOM_SHEET_DATA) public data: { names: ExtraMenu[] }
  ) {
    this.menuItems = data.names;

    //       for (let index = 0; index < data.names.length; index++) {
    //         const element = data.names[index] as ExtraMenu;
    // //this.menuItems.push(element);

    //       }
    // this.menuItems = this.menuItems.map(item => {
    //   return {
    //     ...item,
    //     "Url": environment.ReportURL + item.Url + environment.ReportFixParameter + item.ReportParameter
    //   }
    // })
    //this.menuItems = data.names[0];


  }
  public menuItems: ExtraMenu[] = [];
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }


}
export class ExtraMenu {
  Title: string
  ReportParameter: string;
  Url: string

}