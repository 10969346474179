

export class TotalAccountingTitleRangeDto {
    GUID: string;
    TotalAccountingTitleID: number;
    TotalAccountingTitleGUID: string;
    FromRange: number;
    ToRange: number;
    Value: number;

}


export class Combo_TotalAccountingTitleRangeDto {
    GUID: string;
    TotalAccountingTitleGUID: string;
    TotalAccountingTitleName: string;
    FromRange: number;
    ToRange: number;
    Value: number;

}


export class List_TotalAccountingTitleRangeDto {
    GUID: string;
    TotalAccountingTitleGUID: string;
    TotalAccountingTitleName: string;
    FromRange: number;
    ToRange: number;
    Value: number;

}
export class PList_TotalAccountingTitleRangeDto {
Data: List_TotalAccountingTitleRangeDto[];
TotalCount: number;
}
