import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { AlertType,AlertIconType } from 'src/app/shared/global.enum';
@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html'
})
export class AlertMessageComponent implements OnInit {
    public AlertIconTypeEnum = AlertIconType;
    public AlertTypeEnum = AlertType;
    @Input() alertIcon:AlertIconType = AlertIconType.happy;
    @Input() alertType = AlertType.success;
    @Input() strongMessage: string = '';
    @Input() message: string = '';
    @Input() dismissible: boolean = false;
    @Output() onDismiss = new EventEmitter<string>();
    AssignAlertTyper:string='success';
    displayStyle='block';

    constructor() { }

    ngOnInit(): void {
    }
    OnPushDismiss(){
        this.displayStyle==='none';
        this.onDismiss.emit();
    }
}
