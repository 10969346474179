

export class FlowProcessDto {
    GUID: string;
    ProcessGUID: string;
    ProcessGUGUID: string;
    UserID: number;
    UserGUID: string;
    PersonnelPostID: number;
    PersonnelPostGUID: string;
    FlowStepArrangeID: number;
    FlowStepArrangeGUID: string;
    Description: string;
    isSign: boolean;
    isAccept: boolean;
    isIgnore: boolean;
    isDone: boolean;

}


export class List_FlowProcessDto {
    GUID: string;
    PersonnelPostName: string;
    Description: string;
    CreatedDateShamsi: string;
    isAccept: boolean;
    isIgnore: boolean;
}

export class PList_FlowProcessDto {
Data: List_FlowProcessDto[];
TotalCount: number;
}

export class Combo_ConditionDto{
    ID: number;
    Name: string;
}
export class Combo_PersonnelPostUserDto{
    GUID: string;
    Name: string;
}