

export class PersonnelDto {
    GUID: string;
    GenderID: number;
    GenderGUID: string;
    SoldierTypeID: number;
    SoldierTypeGUID: string;
    FirstName: string;
    LastName: string;
    PersonnelCode: string;
    BirthDate: string;
    BirthDateShamsi: string;
    BirthLocation: string;
    BirthRegisterLocation: string;
    ShSh: string;
    CodeMeli: string;
    Nationality: string;
    isMarid: boolean;
    Phone: string;
    Mobile: string;
    PhoneNecessary: string;
    HomeAddress: string;
    EmailAddress: string;
    InsuranceNumber: string;
    BankAccountNO: string;
    BankShebaNO: string;
    ActivedRetired: boolean;

}

export class Combo_PersonnelDto{
    GUID: string;
    Name: string;
    FirstName: string;
    LastName: string;
    PersonnelCode: string;
}
export class List_PersonnelDto {
    GUID: string;
    GenderGUID: string;
    GenderName: string;
    SoldierTypeGUID: string;
    SoldierTypeName: string;
    FirstName: string;
    LastName: string;
    PersonnelCode: string;
    BirthDate: string;
    BirthDateShamsi: string;
    BirthLocation: string;
    BirthRegisterLocation: string;
    ShSh: string;
    CodeMeli: string;
    Nationality: string;
    isMarid: boolean;
    Phone: string;
    Mobile: string;
    PhoneNecessary: string;
    HomeAddress: string;
    EmailAddress: string;
    InsuranceNumber: string;
    BankAccountNO: string;
    BankShebaNO: string;
    ActivedRetired: boolean;

}
export class PList_PersonnelDto {
Data: List_PersonnelDto[];
TotalCount: number;
}
