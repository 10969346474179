import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { RequestFormCommissionDto, List_RequestFormCommissionDto, PList_RequestFormCommissionDto} from './request-form-commission.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "RequestFormCommissionService"


@Injectable({
 providedIn: 'root',
})
export class RequestFormCommissionService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(RequestFormGUID = ''): Promise<RequestFormCommissionDto> {
   let returnObject = new RequestFormCommissionDto()
   await this.RepositoryService.getItemAsync('RequestFormCommission/Get_Item(RequestFormGUID='+ RequestFormGUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<RequestFormCommissionDto> {
   let returnObject = new RequestFormCommissionDto()
   await this.RepositoryService.getItemAsync('RequestFormCommission/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_RequestFormCommission) => {
           returnObject = _RequestFormCommission;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_RequestFormCommissionDto>
{
   let returnList = new PList_RequestFormCommissionDto();
   await this.RepositoryService.getAllAsync('RequestFormCommission/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_RequestFormCommissionDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(RequestFormGUID: string , ActivePersonnelPostGUID: string  ,pagingString = ''): Promise<PList_RequestFormCommissionDto> {
       let returnList = new PList_RequestFormCommissionDto();
     await  this.RepositoryService.getAllAsync('RequestFormCommission/Get_All(RequestFormGUID='+RequestFormGUID+',ActivePersonnelPostGUID='+ActivePersonnelPostGUID+')' + pagingString)
         .then((res) => {
           returnList.Data = res['value'] as List_RequestFormCommissionDto[];
           returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_RequestFormCommissionDto: RequestFormCommissionDto): Promise<RequestFormCommissionDto> {
           let returnvalue: RequestFormCommissionDto = null;
           await this.RepositoryService.createAsync('RequestFormCommission/Create_Custom', _RequestFormCommissionDto)
             .then((res) => {
                 returnvalue = res as RequestFormCommissionDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }



 async Update_Custom(_RequestFormCommissionDto: RequestFormCommissionDto): Promise<RequestFormCommissionDto> {
               let returnvalue: RequestFormCommissionDto = null;
               await this.RepositoryService.updateAsync('RequestFormCommission/Update_Custom', _RequestFormCommissionDto)
                 .then((res) => {
                     returnvalue = res as RequestFormCommissionDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('RequestFormCommission/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }


}
