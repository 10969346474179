

export class StartupTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_StartupTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_StartupTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_StartupTypeDto {
Data: List_StartupTypeDto[];
TotalCount: number;
}
