

export class CertificateTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_CertificateTypeDto {
    GUID: string;
    Name: string;

}


export class List_CertificateTypeDto {
    GUID: string;
    Name: string;

}
export class PList_CertificateTypeDto {
Data: List_CertificateTypeDto[];
TotalCount: number;
}
