

export class RequestStatusTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_RequestStatusTypeDto {
    GUID: string;
    Name: string;

}


export class List_RequestStatusTypeDto {
    GUID: string;
    Name: string;

}
export class PList_RequestStatusTypeDto {
Data: List_RequestStatusTypeDto[];
TotalCount: number;
}
