

export class ContactCertificateDto {
    GUID: string;
    ContactID: number;
    ContactGUID: string;
    CertificateTypeID: number;
    CertificateTypeGUID: string;
    CertificateNO: string;
    Name: string;
    sDate: string;
    sDateShamsi: string;
    ExrireDate: string;
    ExrireDateShamsi: string;

}


export class Combo_ContactCertificateDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    CertificateTypeGUID: string;
    CertificateTypeName: string;
    CertificateNO: string;
    Name: string;
    sDate: string;
    sDateShamsi: string;
    ExrireDate: string;
    ExrireDateShamsi: string;

}


export class List_ContactCertificateDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    CertificateTypeGUID: string;
    CertificateTypeName: string;
    CertificateNO: string;
    Name: string;
    sDate: string;
    sDateShamsi: string;
    ExrireDate: string;
    ExrireDateShamsi: string;

}
export class PList_ContactCertificateDto {
Data: List_ContactCertificateDto[];
TotalCount: number;
}
