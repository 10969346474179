import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../shared/error.service';
import { MessageService } from '../../../shared/message.service';
import { Injectable } from '@angular/core';


import { UserProfileDto } from './user-profile.model';
import { environment } from 'src/environments/environment';



const serviceName = "UserProfileService"


@Injectable({
    providedIn: 'root',
})
export class UserProfileService {
    constructor(
            protected RepositoryService: RepositoryService,
        protected messageService: MessageService,
        protected toastr: ToastrService,
        protected langService: LangService,
        protected errorService: ErrorService,
        protected generalService: GeneralService
    ) {
    }


    ngOnInit(): void {

    }

    async Get_Item(): Promise<UserProfileDto> {
        let returnObject = new UserProfileDto()
        await this.RepositoryService.getItemAsync('SetareganUser/Get_UserProfile')
            .then(
                (res) => {
                    returnObject = res;
                }
            ).catch(
                (error) => {
                    this.errorService.Failed(error);
                    return undefined;
                });
        return returnObject;
    }


    async UserChangePassword(OldPassworrd = '', NewPassowrd =''): Promise<UserProfileDto> {
        let returnObject = new UserProfileDto()
        await this.RepositoryService.getItemAsync('SetareganUser/UserChangePassowrd(OldPassword=\'' + OldPassworrd + '\',NewPassword=\''+NewPassowrd+'\')')
            .then(
                (_CauseTest) => {
                    returnObject = _CauseTest;
                    
this.messageService.Success("کلمه عبور شما با موفقیت تغییر یافت","😃");
                    

                }
            ).catch(
                (error) => {
                    this.errorService.Failed(error);
                    return undefined;
                }
            );
        return returnObject;
    }





    async Create_Custom(_UserProfileDto: UserProfileDto): Promise<UserProfileDto> {
        let returnvalue: UserProfileDto = null;
        await this.RepositoryService.createAsync('Cause--Test/Create_Custom', _UserProfileDto)
            .then((res) => {
                returnvalue = res as UserProfileDto;
                this.messageService.Success("اطلاعات با موفقیت ذخیره گردید","😃");
            }).catch(
                (error) => {
                    this.errorService.Failed(error);
                    returnvalue=undefined;
                });
        return returnvalue;

    }


    async Update_Custom(_UserProfileDto: UserProfileDto): Promise<UserProfileDto> {
        let returnvalue: UserProfileDto = null;
        await this.RepositoryService.updateAsync('Cause--Test/Update_Custom', _UserProfileDto)
            .then((res) => {
                returnvalue = res as UserProfileDto;
            }).catch(
                (error) => {
                    this.errorService.Failed(error);
                    returnvalue=undefined;
                });
        return returnvalue;

    }

}
