

export class PaymentTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_PaymentTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_PaymentTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_PaymentTypeDto {
Data: List_PaymentTypeDto[];
TotalCount: number;
}
