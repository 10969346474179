import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { Combo_ConditionDto, Combo_PersonnelPostUserDto, FlowProcessDto, List_FlowProcessDto, PList_FlowProcessDto } from './flow-process.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName = "FlowProcessService"


@Injectable({
  providedIn: 'root',
})
export class FlowProcessService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<FlowProcessDto> {
    let returnObject = new FlowProcessDto()
    await this.RepositoryService.getItemAsync('FlowProcess/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<FlowProcessDto> {
    let returnObject = new FlowProcessDto()
    await this.RepositoryService.getItemAsync('FlowProcess/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_FlowProcess) => {
          returnObject = _FlowProcess;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_FlowProcessDto> {
    let returnList = new PList_FlowProcessDto();
    await this.RepositoryService.getAllAsync('FlowProcess/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_FlowProcessDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(PersonnelPostGUID: string , RequestFormGUID: string): Promise<PList_FlowProcessDto> {
    let returnList = new PList_FlowProcessDto();
    await this.RepositoryService.getAllAsync('FlowProcess/Get_All(PersonnelPostGUID='+PersonnelPostGUID+',RequestFormGUID='+RequestFormGUID+')')
      .then((res) => {
        returnList.Data = res['value'] as List_FlowProcessDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }




  async CheckCurrent(PersonnelPostGUID: string, RequestFormGUID: string): Promise<boolean> {
    let returnList = false;
    await this.RepositoryService.getItemAsync('FlowProcess/CheckCurrent(PersonnelPostGUID=' + PersonnelPostGUID + ',RequestFormGUID=' + RequestFormGUID + ')')
      .then((res) => {
        returnList = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_FlowProcessDto: FlowProcessDto): Promise<FlowProcessDto> {
    let returnvalue: FlowProcessDto = null;
    await this.RepositoryService.createAsync('FlowProcess/Create_Custom', _FlowProcessDto)
      .then((res) => {
        returnvalue = res as FlowProcessDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_FlowProcessDto: FlowProcessDto): Promise<FlowProcessDto> {
    let returnvalue: FlowProcessDto = null;
    await this.RepositoryService.updateAsync('FlowProcess/Update_Custom', _FlowProcessDto)
      .then((res) => {
        returnvalue = res as FlowProcessDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FlowProcess/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  

  


  async LoadCombo_AllConditions(RequestFormGUID: string): Promise<Combo_ConditionDto[]> {
    let returnvalue: Combo_ConditionDto[] = [];
    await this.RepositoryService.getAllAsync('FlowProcess/LoadCombo_AllConditions(RequestFormGUID='+RequestFormGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_ConditionDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async LoadCombo_AllPersonnelPostUsers(RequestFormGUID: string,ConditionID: number): Promise<Combo_PersonnelPostUserDto[]> {
    let returnvalue: Combo_PersonnelPostUserDto[] = [];
    await this.RepositoryService.getAllAsync('FlowProcess/LoadCombo_AllPersonnelPostUsers(RequestFormGUID='+RequestFormGUID+',ConditionID='+ConditionID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_PersonnelPostUserDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


}
