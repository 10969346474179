import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StartupPageComponent } from './startup-page/startup-page.component';
import { IndexComponent } from './dashboard/index/index.component';
import { RegisterComponent } from './pages/register/register.component';
import { LoginComponent } from './pages/login/login.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { Error400Component } from './pages/error400/error400.component';
import { Error403Component } from './pages/error403/error403.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error503Component } from './pages/error503/error503.component';

const routes: Routes = [
  { path: '', redirectTo: 'Loan/base', pathMatch: 'full' },
  {
    path: 'Loan', component: StartupPageComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: 'index', component: IndexComponent },
      { path: 'base', loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule) },
      { path: 'loan', loadChildren: () => import('./views/loan/loan.module').then(m => m.LoanModule) },
    ]
  },


  { path: 'login', component: LoginComponent },
  { path: 'page-register', component: RegisterComponent },
  { path: 'page-lock-screen', component: LockScreenComponent },
  { path: 'page-forgot-password', component: ForgotPasswordComponent },
  { path: 'page-error-400', component: Error400Component },
  { path: 'page-error-403', component: Error403Component },
  { path: 'page-error-404', component: Error404Component },
  { path: 'page-error-500', component: Error500Component },
  { path: 'page-error-503', component: Error503Component },



  { path: '**', component: Error404Component },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
