

export class TotalAccountingTitleDto {
    GUID: string;
    GroupID: number;
    Name: string;
    ZaribZemanat: number;
    ZaribTaahod: number;

}


export class Combo_TotalAccountingTitleDto {
    GUID: string;
    Name: string;

}


export class List_TotalAccountingTitleDto {
    GUID: string;
    GroupID: number;
    GroupName: string;
    Name: string;
    ZaribZemanat: number;
    ZaribTaahod: number;

}
export class PList_TotalAccountingTitleDto {
Data: List_TotalAccountingTitleDto[];
TotalCount: number;
}
