

export class LastStatusZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_LastStatusZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_LastStatusZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_LastStatusZemanatTypeDto {
Data: List_LastStatusZemanatTypeDto[];
TotalCount: number;
}
