

export class ManagerResumeTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_ManagerResumeTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_ManagerResumeTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_ManagerResumeTypeDto {
Data: List_ManagerResumeTypeDto[];
TotalCount: number;
}
