import { Component, OnInit,HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
    
    public currentHref: string = "";
    public appVersion: string = "";
    screenWidth: number;

  constructor(location: Location 
    ,private router: Router
    ) {
      this.getScreenSize(null);
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.currentHref = location.path();
      } else {
        this.currentHref = 'Home'
      }
    });
  }
  @HostListener('window:resize', ['$event'])
	getScreenSize(event?) {
	//	this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}
  LogOut(){
		localStorage.clear();
		this.router.navigate(['/login']);
	}

  ngOnInit(): void {
    this.appVersion = environment.appVersion;
  }
  
  toggleIcon: boolean = true;
  


    toggleLoveIcon() {
        this.toggleIcon = !this.toggleIcon;
    }
    
    loanArray = [
      '/loan/RequestForms',
    ];
  
    baseArray= [
      '/base/Contacts',
    ];

}
