

export class KnowledgeTypeDto {
    GUID: string;
    Name: string;
    PercentValue1: number;
    PercentValue2: number;
    PercentValue3: number;

}


export class Combo_KnowledgeTypeDto {
    GUID: string;
    Name: string;
    PercentValue1: number;
    PercentValue2: number;
    PercentValue3: number;

}


export class List_KnowledgeTypeDto {
    GUID: string;
    Name: string;
    PercentValue1: number;
    PercentValue2: number;
    PercentValue3: number;

}
export class PList_KnowledgeTypeDto {
Data: List_KnowledgeTypeDto[];
TotalCount: number;
}
