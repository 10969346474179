

export class ExpertOpinionTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_ExpertOpinionTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_ExpertOpinionTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_ExpertOpinionTypeDto {
Data: List_ExpertOpinionTypeDto[];
TotalCount: number;
}
