

export class MasterStatusZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_MasterStatusZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_MasterStatusZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_MasterStatusZemanatTypeDto {
Data: List_MasterStatusZemanatTypeDto[];
TotalCount: number;
}
