

export class AccountingTitleDto {
    GUID: string;
    AccountingTitleGroupID: number;
    AccountingTitleGroupGUID: string;
    Name: string;

}


export class Combo_AccountingTitleDto {
    GUID: string;
    AccountingTitleGroupGUID: string;
    AccountingTitleGroupName: string;
    Name: string;

}


export class List_AccountingTitleDto {
    GUID: string;
    AccountingTitleGroupGUID: string;
    AccountingTitleGroupName: string;
    Name: string;

}
export class PList_AccountingTitleDto {
Data: List_AccountingTitleDto[];
TotalCount: number;
}
