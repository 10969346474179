

export class ImmovableTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_ImmovableTypeDto {
    GUID: string;
    Name: string;

}


export class List_ImmovableTypeDto {
    GUID: string;
    Name: string;

}
export class PList_ImmovableTypeDto {
Data: List_ImmovableTypeDto[];
TotalCount: number;
}
