import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AttachmentDto } from './upload-document.model';
import { RepositoryService } from 'src/app/_services/repository.service';

@Component({
    selector: 'app-upload-document',
    templateUrl: './upload-document.component.html'
})
export class UploadDocumentComponent implements OnInit, OnDestroy {
    @ViewChild('FileComponent', { static: false }) SelectFileElement: ElementRef;

    @Output() onUploadHasError = new EventEmitter<string>();
    @Output() onUploadSuccess = new EventEmitter<AttachmentDto>();
    @Output() onDelete = new EventEmitter<string>();
    @Output() onDeleteComplete = new EventEmitter<string>();
    @Output() onExeption = new EventEmitter<string>();

    @Input() MaxFileSize = 0;
    @Input() KeyGUID = environment.EmptyGUID;
    @Input() AllowFileTypes = '';
    @Input() _CanDownload = true;
    @Input() _CanUpload = true;
    @Input() _CanDelete = true;
    @Input() DocumentGUID = environment.EmptyGUID;
    @Input() DownloadLink = '';
    @Input() ServiceURL = '';
    
    @Input() ExternalKey1 = '';
    @Input() ExternalValue1 = '';
    @Input() ExternalKey2 = '';
    @Input() ExternalValue2 = '';
    @Input() ExternalKey3 = '';
    @Input() ExternalValue3 = '';

    FileErrorMessage = '';
    StartUploading = false;
    SelectedFile: File = null;

    constructor(private RepositoryService: RepositoryService,

    ) { }

    ngOnInit(): void {
        // console.log('show upload component')
    }
    ngOnDestroy(): void {
        this.DownloadLink = '';
        this.KeyGUID = environment.EmptyGUID;
        this.DocumentGUID = '';
        this.SelectedFile = null;
        
    }

    onSelectFile(event) {
        this.FileErrorMessage = '';
        //,_ProjectGUID,_ComponentID
        this.SelectedFile = event.target.files[0] as File;
        if (this.MaxFileSize !== 0) {
            if (this.SelectedFile.size > this.MaxFileSize * 1024 * 1024) {
                this.FileErrorMessage = 'حداکثر حجم مجاز ' + this.MaxFileSize + 'MB میباشد';
                this.StartUploading = false;
                // this.SelectFileElement.nativeElement.value = null;
                return;
            }
        }
        this.doUpload();
    }
    doUpload() {
        this.StartUploading = true;
        const _Attachment = new FormData();
        _Attachment.append('selectedFile', this.SelectedFile, this.SelectedFile.name);
        if (this.ExternalKey1.trim().length > 0) { _Attachment.append(this.ExternalKey1.trim(), this.ExternalValue1.trim()); }
        if (this.ExternalKey2.trim().length > 0) { _Attachment.append(this.ExternalKey2.trim(), this.ExternalValue2.trim()); }
        if (this.ExternalKey3.trim().length > 0) { _Attachment.append(this.ExternalKey3.trim(), this.ExternalValue3.trim()); }
        if (this.ServiceURL.trim().length === 0) {
            this.ServiceURL = 'Attachment/UploadFile/';
        }
        this.RepositoryService.UploadAsync(this.ServiceURL, _Attachment)
            .then(
                (_Attachment) => {
                    const attachment = _Attachment['body'] as AttachmentDto;
                    this.DocumentGUID = attachment.DocumentGUID;
                    this.DownloadLink = attachment.DocumentFullPath;
                    this.onUploadSuccess.emit(attachment);
                    this.StartUploading = false;
                   
                    this.SelectFileElement.nativeElement.value = null;
                }
            ).catch(
                (error) => {
                    this.StartUploading = false;

                    this.onExeption.emit(error);
                    this.SelectFileElement.nativeElement.value = null;
                }
            );

    }
    RemoveFile() {
        this.onDelete.emit();
        this.StartUploading = false;
        this.SelectedFile = null;
        this.FileErrorMessage = '';
        this.DocumentGUID = '';
        this.SelectFileElement.nativeElement.value = null;
    }
}
