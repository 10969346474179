import { Injectable } from '@angular/core';
import { LangService } from 'src/app/shared/lang.service';
import { hlpDate } from 'src/app/_models';
import { RepositoryService } from 'src/app/_services/repository.service';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertIcon } from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(
    private RepositoryService: RepositoryService,
    private langService: LangService,) {

  }
testRanin(){
  return null;
}
  async SetGlobalPermission(ComponentName: string, PostGUID: string = environment.EmptyGUID): Promise<string[]> {
    var ToReturn: string[] = []; 
    await this.RepositoryService.getAllAsync('ComponentPermission/GetGlobal(ComponentName=\'' + ComponentName + '\',PostGUID=' + PostGUID + ')')
      .then((res) => {
        ToReturn= res['value'] as string[];

      }).catch(
        (error) => {
          ToReturn= [];
        }
      );
    return ToReturn;
  }

  async SetBusinessPermisson(ComponentName: string, PostGUID: string = environment.EmptyGUID): Promise<string[]> {
   var ToReturn: string[] = []; 
   
    await this.RepositoryService.getAllAsync('ComponentPermission/GetBussiness(ComponentName=\'' + ComponentName + '\',PostGUID=' + PostGUID+')')
      .then((res) => {
        ToReturn =  res['value'] as string[];

      }).catch(
        (error) => {
          ToReturn =  [];
        }
      );
    return ToReturn;
  }

  GetPermissionValue(PermissionList: string[], KeyParam: string): boolean {
    return PermissionList.includes(KeyParam);
  }

  //TODO: Move to base service
  refreshPage(): void {
    window.location.reload();
  }
  //TODO: Move to base service
  async ConfirmDialog(Title = '',
    MessageBody = '',
    Icon: SweetAlertIcon = 'warning') {

    const Yes = this.langService.TranslateKey("General.Yes");
    const Cancel = this.langService.TranslateKey("General.Cancel");
let Result = false;
    await Swal.fire({
      title: Title,
      text: MessageBody,
      icon: Icon,
      showCancelButton: true,
      confirmButtonText: Yes,
      cancelButtonText: Cancel
    }).then((Continue) => {
      if (Continue.value) {
        Result= true;
      }
      else {
        Result= false;
      }
    });
    return Result;
  }


  async Get_LastDate(Date: hlpDate): Promise<string> {
    let returnList = '';
    await this.RepositoryService.getItemExteraAsync( 'General/Get_LastDate/' , Date)
      .then((res) => {
        returnList = res['value'];
      }).catch(
        (error) => {
          
          returnList= undefined;
        }
      );
    return returnList;
  }

  async Get_PersianNextDate(Date: hlpDate): Promise<string> {
    let returnList = '';
    await this.RepositoryService.getItemExteraAsync( '/General/Get_PersianNextDate/' , Date)
      .then((res) => {
        returnList = res['value'];
      }).catch(
        (error) => {
          
          returnList= undefined;
        }
      );
    return returnList;
  }
  async Get_NextDate(Date: hlpDate): Promise<string> {
    let returnList = '';
    await this.RepositoryService.getItemExteraAsync( '/General/Get_NextDate/' , Date)
      .then((res) => {
        returnList = res['value'];
      }).catch(
        (error) => {
          
          returnList= undefined;
        }
      );
    return returnList;
  }
  
  // let ObjectParam: ParameterKeyValues[] = [];
  // ObjectParam.push({ KeyName: 'component', KeyValue: 'BudgetType', isString: true });
  // ObjectParam.push({ KeyName: 'UserInvolvedCompanyComponentRoleGUID', KeyValue: UserInvolvedCompanyComponentRoleGUID, isString: false });
  // const ServiceParam = this.RepositoryService.ParametersConcat(ObjectParam);
  //ttt() {
  //  let ObjectParam: RepositoryParameter[] = [];
  //  ObjectParam = this.SetParamter2('asdfasdf', 'asfasdf', true, ObjectParam);
  //  ObjectParam = this.SetParamter2('asdfasdf', 'asfasdf', true, ObjectParam);
  //  const serviceParam = GeneralService.ParameterConcat(ObjectParam);


  //  ObjectParam.push(GeneralService.SetParamter('asdfasdf', 'asfasdf', true));
  //  ObjectParam.push(GeneralService.SetParamter('asdfasdf', 'asfasdf', true));
  //  const serviceParam = GeneralService.ParameterConcat(ObjectParam);
  //}

  public SetParamter2(keyName: string, keyValye: string, isString: boolean, PreList: RepositoryParameter[]): RepositoryParameter[] {
    let returnParam: RepositoryParameter = new RepositoryParameter();
    returnParam.KeyName = keyName;
    returnParam.KeyValue = keyValye;
    returnParam.isString = isString;
    PreList.push(returnParam)
    return PreList;
  }

  public SetParamter(keyName: string, keyValye: string, isString: boolean): RepositoryParameter {
    let returnParam: RepositoryParameter = new RepositoryParameter();
    returnParam.KeyName = keyName;
    returnParam.KeyValue = keyValye;
    returnParam.isString = isString;

    return returnParam;

  }

  public ParametersConcat(lst: RepositoryParameter[]): string {
    const ParamString = lst.map(x => x.KeyName + '=' + (x.isString ? '\'' : '') + x.KeyValue + (x.isString ? '\'' : '')).join(',');
    return ParamString;
  }

  public CheckValidGUID(input: any): boolean {
    if (input && input != null && input != environment.EmptyGUID && String(input).length == 36) {
      var pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (pattern.test(String(input))) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  public cNumber(input: any): number {
    if (input && input!=null && (Number(input)>0 || Number(input)<=0)) {
      return Number(input);
    }
    else {
      return 0;
    }
  }

  
  public cString(input: any): string {
    if (input && input!=null ) {
      return input;
    }
    else {
      return '';
    }
  }

  public NumberIsZiroOrPostive(input: any): boolean {
    if ( Number(input)>=0) {
      return true
    }
    else {
      return false;
    }
  }

  public NumberABS(input: any): number {
    if (input && input!=null ) {
      if( Number(input)>0)
      {return Number(input)}
      else
      {return Number(input)*-1}
    }
    else {
      return 0;
    }
  }

  public cGuid(input: any): string {
    if (this.CheckValidGUID(input)) {
      return String(input);
    }
    else {
      return environment.EmptyGUID;
    }
  }
  public cBool(input: any): boolean {
    if (input) {
      return typeof input === "boolean";
    }
    else {
      return false;
    }
  }
  public CheckArrayHasData(input: any): boolean {
    if (input && Array.isArray(input) && input.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  public isNull(input: any):boolean{
    if(!input || input==null){
      return true
    } else {return false;}
  }
  public isString(x: any): x is string {
    return typeof x === "string";
  }
  public isNumber(x: any): x is number {
    return typeof x === "number";
  }
  public GetLenght(input: any ):number{
    if(input){
      if(Array.isArray(input)){
        return input.length;
      }else if( typeof input === "string"){
       return input.trim().length;
      } else if(typeof input === "number"){
        return input.toString().length;
      }
      else{
        return 0;
      }
    }

    return 0;
  }
}

export class RepositoryParameter {

  KeyName: string;
  KeyValue: string;
  isString: boolean
}
