

export class AuditorTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_AuditorTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_AuditorTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_AuditorTypeDto {
Data: List_AuditorTypeDto[];
TotalCount: number;
}
