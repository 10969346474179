

export class UserRoleDto {
    GUID: string;
    SubOfficeGUID: string;
    UserGUID: string;
    RoleGUIDs: string[];

}


export class List_UserRoleDto {
    GUID: string;
    SoftwareGUID: string;
    SoftwareName: string;
    RoleGUID: string;
    RoleName: string;

}
export class PList_UserRoleDto {
Data: List_UserRoleDto[];
TotalCount: number;
}



export class Filter_UserRoleDto {
    UserGUID: string;
    OfficeGUID: string;
    SubOfficeGUIDs: string[];
    SoftwareGUIDs: string[];
}




