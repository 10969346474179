

export class FileTypeDto {
    GUID: string;
    Name: string;
    isNeeded: boolean;
    GenerateFunction: string;
    ProcessFunction: string;
    StaticFile: string;

}


export class Combo_FileTypeDto {
    GUID: string;
    Name: string;
    isNeeded: boolean;
    GenerateFunction: string;
    ProcessFunction: string;
    StaticFile: string;

}


export class List_FileTypeDto {
    GUID: string;
    Name: string;
    isNeeded: boolean;
    GenerateFunction: string;
    ProcessFunction: string;
    StaticFile: string;

}
export class PList_FileTypeDto {
Data: List_FileTypeDto[];
TotalCount: number;
}
