
<!--**********************************
    Header start
***********************************-->
<div class="header">
    <div class="header-content">
        <nav class="navbar navbar-expand">
            <div class="collapse navbar-collapse justify-content-between">
                <div class="header-left1">
                    <div class="dashboard_bar">
                        <div class="container-fluid" style="min-width: 400px;"  >
                            <div class="row">
                                <div class="col-12" >
                                    <mat-form-field appearance="outline" class="col-12"
                                        style="position: relative;margin-top: 10px ;color: black !important;"   *ngIf="screenWidth>=600" >
                                        <!-- <mat-label>{{'SubOfficeInvolvedCompanyComponentPost.UserPostName' | translate}}&nbsp;
                                        </mat-label> -->
                                        <input type="text" aria-label="Number" matInput
                                            [formControl]="myUserPostControl" [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUserPost">
                                            <mat-option *ngFor="let UserPost of filteredUserPosts | async"
                                                [value]="UserPost"
                                                (onSelectionChange)="onSelectedUserPost($event.source.selected, UserPost)">
                                               {{ UserPost.PostName}} 
                                            </mat-option>
                                        </mat-autocomplete>
                                        <button type="button" mat-icon-button aria-label="Clear" class="SearchSpiner"
                                            style="top:3px;left: 5px;position: absolute;font-size: large;"
                                            (click)="this.myUserPostControl.setValue('');">
                                            <span class="material-icons">
                                                list
                                            </span>
                                        </button>
                                    </mat-form-field>


                                     <mat-form-field appearance="outline"  style="position: relative;margin-top: 10px ;margin-right: 25px;"  class="col-9"  *ngIf="screenWidth<600">
                                        <mat-select  >
                                            <mat-option *ngFor="let UserPost of AllUserPosts"
                                            [value]="UserPost.PostGUID" (onSelectionChange)="onSelectedUserPost(true, UserPost)"> 
                                            {{ UserPost.PostName}} 
                                        </mat-option>
                                        </mat-select>
                                    </mat-form-field> 
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <ul class="navbar-nav header-right">
                    <li class="nav-item dropdown header-profile dropdown-no-icon" ngbDropdown>
                        <a class="nav-link" click="javascript:0 " Post="button" data-toggle="dropdown" ngbDropdownToggle>
                            <img src="assets/images/profile/pic1.jpg" width="20" alt=""/>
                            <div class="header-info">
                                <span>{{Username}}</span>
                                <small>{{UserEmail}}</small>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <a href="javascript:void(0);" (click)="LoadUserProfile();UserProfileModal.show();" class="dropdown-item ai-icon">
                                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                <span class="ml-2">Profile </span>
                            </a>
                            <a href="javascript:void(0);" (click)="LogOut()" class="dropdown-item ai-icon">
                                <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                <span class="ml-2">خروج </span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>


<div bsModal  #UserProfileModal="bs-modal" [config]="{ backdrop: 'static',  keyboard: false}" class="modal modal-xl fade" tabindex="-1" Post="dialog" aria-labelledby="UserProfileModal" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header custome-background-color">
        <h4 class="modal-title">{{'General.UserProfile'| translate}}</h4>
        <button type = "button" class= "close"(click) = "UserProfileModal.hide()"aria-label = "Close" >
             <span aria-hidden = "true" class= "custome-background-color" > &times;</span>
         </button>
     </div>
     <div class= "modal-body" >
        <cmp-user-profile-component #UserProfileComponent></cmp-user-profile-component>
       </div>
     </div>
  </div>
 

