

export class ContactFamilyDto {
    GUID: string;
    ContactID: number;
    ContactGUID: string;
    Name: string;
    RelationType: string;
    FatherName: string;
    BirthDate: string;
    BirthDateShamsi: string;
    shsh: number;
    NationalCode: string;
    SharePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class Combo_ContactFamilyDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    RelationType: string;
    FatherName: string;
    BirthDate: string;
    BirthDateShamsi: string;
    shsh: number;
    NationalCode: string;
    SharePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class List_ContactFamilyDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    RelationType: string;
    FatherName: string;
    BirthDate: string;
    BirthDateShamsi: string;
    shsh: number;
    NationalCode: string;
    SharePercent: number;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}
export class PList_ContactFamilyDto {
Data: List_ContactFamilyDto[];
TotalCount: number;
}
