import { environment } from 'src/environments/environment';
import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { RequestFormDto, List_RequestFormDto, PList_RequestFormDto, RequestFormDetailDto, DownloadDto, RequestFormFlowDto, RequestFormPaymentDto } from './request-form.model'
import { ErrorService } from 'src/app/shared/error.service';
import { RequestFormCommissionCompleteDto } from '../request-form-commission/request-form-commission.model';



const serviceName = "RequestFormService"


@Injectable({
  providedIn: 'root',
})
export class RequestFormService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<RequestFormDto> {
    let returnObject = new RequestFormDto()
    await this.RepositoryService.getItemAsync('RequestForm/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_DetailItem(GUID = ''): Promise<RequestFormDetailDto> {
    let returnObject = new RequestFormDetailDto()
    await this.RepositoryService.getItemAsync('RequestForm/Get_DetailItem(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }

  

  
  async Get_PaymentItem(GUID = ''): Promise<RequestFormPaymentDto> {
    let returnObject = new RequestFormPaymentDto()
    await this.RepositoryService.getItemAsync('RequestForm/Get_PaymentItem(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }



  async Get_FlowItem(GUID = ''): Promise<string> {
    let returnObject = environment.EmptyGUID
    await this.RepositoryService.getItemAsync('RequestForm/Get_FlowItem(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res['value'];
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<RequestFormDto> {
    let returnObject = new RequestFormDto()
    await this.RepositoryService.getItemAsync('RequestForm/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_RequestForm) => {
          returnObject = _RequestForm;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_RequestFormDto> {
    let returnList = new PList_RequestFormDto();
    await this.RepositoryService.getAllAsync('RequestForm/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_RequestFormDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(RequestTypeGUID: string ,PersonnelPostGUID: string, pagingString = ''): Promise<PList_RequestFormDto> {
    let returnList = new PList_RequestFormDto();
    await this.RepositoryService.getAllAsync('RequestForm/Get_All(RequestTypeGUID='+RequestTypeGUID+',PersonnelPostGUID='+PersonnelPostGUID+')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_RequestFormDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_RequestFormDto: RequestFormDto): Promise<RequestFormDto> {
    let returnvalue: RequestFormDto = null;
    await this.RepositoryService.createAsync('RequestForm/Create_Custom', _RequestFormDto)
      .then((res) => {
        returnvalue = res as RequestFormDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_RequestFormDto: RequestFormDto): Promise<RequestFormDto> {
    let returnvalue: RequestFormDto = null;
    await this.RepositoryService.updateAsync('RequestForm/Update_Custom', _RequestFormDto)
      .then((res) => {
        returnvalue = res as RequestFormDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          
        });
    return returnvalue;
  }


  async Update_DetailCustom(_RequestFormDetailDto: RequestFormDetailDto): Promise<RequestFormDetailDto> {
    let returnvalue: RequestFormDetailDto = null;
    await this.RepositoryService.updateAsync('RequestForm/Update_DetailCustom', _RequestFormDetailDto)
      .then((res) => {
        returnvalue = res as RequestFormDetailDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  
  async Update_PaymentCustom(_RequestFormPaymentDto: RequestFormPaymentDto): Promise<RequestFormPaymentDto> {
    let returnvalue: RequestFormPaymentDto = null;
    await this.RepositoryService.updateAsync('RequestForm/Update_PaymentCustom', _RequestFormPaymentDto)
      .then((res) => {
        returnvalue = res as RequestFormPaymentDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  
  async Update_FlowCustom(_RequestFormFlowDto: RequestFormFlowDto): Promise<RequestFormFlowDto> {
    let returnvalue: RequestFormFlowDto = null;
    await this.RepositoryService.updateAsync('RequestForm/Update_FlowCustom', _RequestFormFlowDto)
      .then((res) => {
        returnvalue = res as RequestFormFlowDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('RequestForm/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Download(_DownloadDto: DownloadDto): Promise<string> {
    let returnStr = "";
    await this.RepositoryService.getItemExteraAsync('RequestForm/Download/', _DownloadDto)
      .then((res) => {
        returnStr = res['value'];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnStr;
  }


  
  async DownloadLetter(GUID: string): Promise<string> {
    let returnStr = "";
    await this.RepositoryService.getItemAsync('RequestForm/DownloadLetter(RequestFormGUID='+GUID+')')
      .then((res) => {
        returnStr = res['value'];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnStr;
  }


  async LoadAllAccountingTitle(_Attachment: FormData): Promise<boolean> {
    let returnObject = false;
    await this.RepositoryService.UploadAsync('UploadDocument/LoadAllData', _Attachment)
      .then(
        (_document) => {
          returnObject = true
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          returnObject = undefined;
        }
      );
    return returnObject;
  }


  async RequestFormCommissionComplete(RequestFormCommissionComplete: RequestFormCommissionCompleteDto): Promise<boolean> {
    let returnObject = false
    await this.RepositoryService.getItemExteraAsync('RequestForm/RequestFormCommissionComplete' , RequestFormCommissionComplete)
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


    



}
