

export class ActivityTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_ActivityTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_ActivityTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_ActivityTypeDto {
Data: List_ActivityTypeDto[];
TotalCount: number;
}
