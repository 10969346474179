

export class ContactManagerDto {
    GUID: string;
    ContactID: number;
    ContactGUID: string;
    Name: string;
    FatherName: string;
    ShSh: number;
    RegisterLocation: string;
    NationalCode: string;
    Post: string;
    Mobile: string;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class Combo_ContactManagerDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    FatherName: string;
    ShSh: number;
    RegisterLocation: string;
    NationalCode: string;
    Post: string;
    Mobile: string;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;

}


export class List_ContactManagerDto {
    GUID: string;
    ContactGUID: string;
    ContactName: string;
    Name: string;
    FatherName: string;
    ShSh: number;
    RegisterLocation: string;
    NationalCode: string;
    Post: string;
    Mobile: string;
    isActive: boolean;
    FromDate: string;
    FromDateShamsi: string;
    ToDate: string;
    ToDateShamsi: string;
    InqueryValue: number;

}


export class List_ContactManagerInqueryDto {
    GUID: string;
    InqueryValue: number;
}



export class PList_ContactManagerDto {
Data: List_ContactManagerDto[];
TotalCount: number;
}
