

export class PostDto {
    GUID: string;
    ParentID: number;
    ParentGUID: string;
    Name: string;
    OfficialTitle: string;
    isManager: boolean;
    isSign: boolean;

}


export class Combo_PostDto {
    GUID: string;
    Name: string;
}


export class List_PostDto {
    GUID: string;
    ParentGUID: string;
    ParentName: string;
    Name: string;
    OfficialTitle: string;
    isManager: boolean;
    isSign: boolean;

}
export class PList_PostDto {
Data: List_PostDto[];
TotalCount: number;
}
