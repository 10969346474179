import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from 'src/app/shared/error.service';
import { Injectable } from '@angular/core';


import { ComponentActionDto, Combo_ComponentActionDto, List_ComponentActionDto, PList_ComponentActionDto } from './component-action.model'



const serviceName = "ComponentActionService"


@Injectable({
  providedIn: 'root',
})
export class ComponentActionService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<ComponentActionDto> {
    let returnObject = new ComponentActionDto()
    await this.RepositoryService.getItemAsync('ComponentAction/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<ComponentActionDto> {
    let returnObject = new ComponentActionDto()
    await this.RepositoryService.getItemAsync('ComponentAction/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_ComponentAction) => {
          returnObject = _ComponentAction;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_ComponentActionDto> {
    let returnList = new PList_ComponentActionDto();
    await this.RepositoryService.getAllAsync('ComponentAction/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ComponentActionDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(ComponentGUID: string, pagingString = ''): Promise<PList_ComponentActionDto> {
    let returnList = new PList_ComponentActionDto();
    await this.RepositoryService.getAllAsync('ComponentAction/Get_All(ComponentGUID=' + ComponentGUID + ')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ComponentActionDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_ComponentActionDto: ComponentActionDto): Promise<ComponentActionDto> {
    let returnvalue: ComponentActionDto = null;
    await this.RepositoryService.createAsync('ComponentAction/Create_Custom', _ComponentActionDto)
      .then((res) => {
        returnvalue = res as ComponentActionDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_ComponentActionDto: ComponentActionDto): Promise<ComponentActionDto> {
    let returnvalue: ComponentActionDto = null;
    await this.RepositoryService.updateAsync('ComponentAction/Update_Custom', _ComponentActionDto)
      .then((res) => {
        returnvalue = res as ComponentActionDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('ComponentAction/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo_ByComponentGUID(ComponentGUID: string): Promise<Combo_ComponentActionDto[]> {
    let returnvalue: Combo_ComponentActionDto[] = [];
    await this.RepositoryService.getAllAsync('ComponentAction/LoadCombo_ByComponentGUID(ComponentGUID='+ComponentGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_ComponentActionDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }



}
