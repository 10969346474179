

export class LastTashilatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_LastTashilatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_LastTashilatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_LastTashilatTypeDto {
Data: List_LastTashilatTypeDto[];
TotalCount: number;
}
