import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class PostHandlerService {

  constructor() { }

  //CurrentPost
  private _CurrentPostGUID: string;
  SetCurrentPostGUID(CurrentOfficeGUID: string) {
    this._CurrentPostGUID = CurrentOfficeGUID;
  }
  GetCurrentPostGUID() {
    return this._CurrentPostGUID;
  }
  //CurrentPost


  // Observable string sources
  private ChangePost = new Subject<string>();

  // Observable string streams
  Observable_ChangePost = this.ChangePost.asObservable();

  // Service message commands
  ChangePost_Handler(_PostGUID: string) {
    this.SetCurrentPostGUID(_PostGUID);
    this.ChangePost.next(_PostGUID);
  }


  completePost() {

    this.ChangePost = new Subject<string>();
    this.Observable_ChangePost = this.ChangePost.asObservable();

  }


}
