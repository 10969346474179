import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { ManagerResumeTypeDto, Combo_ManagerResumeTypeDto, List_ManagerResumeTypeDto, PList_ManagerResumeTypeDto} from './manager-resume-type.model'
import { ErrorService } from 'src/app/shared/error.service';



const serviceName= "ManagerResumeTypeService"


@Injectable({
 providedIn: 'root',
})
export class ManagerResumeTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<ManagerResumeTypeDto> {
   let returnObject = new ManagerResumeTypeDto()
   await this.RepositoryService.getItemAsync('ManagerResumeType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<ManagerResumeTypeDto> {
   let returnObject = new ManagerResumeTypeDto()
   await this.RepositoryService.getItemAsync('ManagerResumeType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_ManagerResumeType) => {
           returnObject = _ManagerResumeType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_ManagerResumeTypeDto>
{
   let returnList = new PList_ManagerResumeTypeDto();
   await this.RepositoryService.getAllAsync('ManagerResumeType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_ManagerResumeTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_ManagerResumeTypeDto> {
       let returnList = new PList_ManagerResumeTypeDto();
     await  this.RepositoryService.getAllAsync('ManagerResumeType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_ManagerResumeTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_ManagerResumeTypeDto: ManagerResumeTypeDto): Promise<ManagerResumeTypeDto> {
           let returnvalue: ManagerResumeTypeDto = null;
           await this.RepositoryService.createAsync('ManagerResumeType/Create_Custom', _ManagerResumeTypeDto)
             .then((res) => {
                 returnvalue = res as ManagerResumeTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_ManagerResumeTypeDto: ManagerResumeTypeDto): Promise<ManagerResumeTypeDto> {
               let returnvalue: ManagerResumeTypeDto = null;
               await this.RepositoryService.updateAsync('ManagerResumeType/Update_Custom', _ManagerResumeTypeDto)
                 .then((res) => {
                     returnvalue = res as ManagerResumeTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('ManagerResumeType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_ManagerResumeTypeDto[]> {
 let returnvalue: Combo_ManagerResumeTypeDto[] = [];
         await this.RepositoryService.getAllAsync('ManagerResumeType/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_ManagerResumeTypeDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
