

export class CompanyAgeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_CompanyAgeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_CompanyAgeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_CompanyAgeDto {
Data: List_CompanyAgeDto[];
TotalCount: number;
}
