

export class LastZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class Combo_LastZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}


export class List_LastZemanatTypeDto {
    GUID: string;
    Name: string;
    PercentValue: number;

}
export class PList_LastZemanatTypeDto {
Data: List_LastZemanatTypeDto[];
TotalCount: number;
}
