

export class StockTypeDto {
    GUID: string;
    Name: string;
    PercentValue1: number;
    PercentValue2: number;
    PercentValue3: number;
    PercentValue4: number;

}


export class Combo_StockTypeDto {
    GUID: string;
    Name: string;
    PercentValue1: number;
    PercentValue2: number;
    PercentValue3: number;
    PercentValue4: number;
}


export class List_StockTypeDto {
    GUID: string;
    Name: string;
    PercentValue1: number;
    PercentValue2: number;
    PercentValue3: number;
    PercentValue4: number;

}
export class PList_StockTypeDto {
Data: List_StockTypeDto[];
TotalCount: number;
}
