

export class FlowStepArrangeConditionDto {
    GUID: string;
    FlowStepArrangeID: number;
    FlowStepArrangeGUID: string;
    ConditionValue: number;
    NextFlowStepArrangeID: number;
    NextFlowStepArrangeGUID: string;
    NextFlowID: number;
    NextFlowGUID: string;
    isDefault: boolean;

}


export class Combo_FlowStepArrangeConditionDto {
    GUID: string;
    FlowStepArrangeGUID: string;
    FlowStepArrangeName: string;
    ConditionValue: number;
    NextFlowStepArrangeGUID: string;
    NextFlowStepArrangeName: string;
    NextFlowGUID: string;
    NextFlowName: string;
    isDefault: boolean;

}


export class List_FlowStepArrangeConditionDto {
    GUID: string;
    FlowStepArrangeGUID: string;
    FlowStepArrangeName: string;
    ConditionValue: number;
    NextFlowStepArrangeGUID: string;
    NextFlowStepArrangeName: string;
    NextFlowGUID: string;
    NextFlowName: string;
    isDefault: boolean;

}
export class PList_FlowStepArrangeConditionDto {
Data: List_FlowStepArrangeConditionDto[];
TotalCount: number;
}
